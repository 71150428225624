import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {format, parseISO} from 'date-fns';
import {Link} from 'react-router-dom';
import Button from '@mui/material/Button';

import PageContainer from 'components/elements/PageContainer';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import {ROUTE_CONFIGURE_PIPELINES, ROUTE_DATASETS_CONFIGURE} from 'pages/constants';
import {notifySelectedOutcome} from 'state/app';
import AlertCard from 'components/elements/AlertCard';
import useTriggerOpportunitiesPipeline from 'hooks/pipelines/use-trigger-opportunity-pipeline';
import DatasetSelector from 'components/base/DatasetSelector';

import OpportunitiesView from './opportunities-view';
import Filters from './filters';
import OpportunitiesDrilldown from './opportunities-drilldown';
import Card from "@mui/material/Card";
import useInit from '../../../../hooks/init/use-init';


const RankedOpportunities = () => {
    const {t} = useTranslation();

    return (
        <PageContainer pageTitle={t('feature.investigate.opportunities.pageTitle')}
                       pageSubTitle={t('feature.investigate.opportunities.pageSubTitle')}>
            <OpportunitiesPage/>
        </PageContainer>
    );
};

const OpportunitiesPage = ({containerHeight}) => {

    // const data = useSelector((state) => state.app.initData);
    const datasetId = useSelector((state) => state.app.dataset);
    const selectedDatasetId = useSelector((state) => state.app.selectedDatasetId);
    const isPDP = useSelector((state) => state.app.isPDP);

    const dispatch = useDispatch();

    const {mutateAsync} = useTriggerOpportunitiesPipeline();

    const [filters, setFilters] = useState({
        impactLever: "all",
        outcome: "",
        show: 20,
        meanFeature: "",
        segment: "all"
    });
    const [segment, setSegment] = useState('');
    const [segmentFeature, setSegmentFeature] = useState('');
    const [lever, setLever] = useState('');
    const [meanFeature, setMeanFeature] = useState('');
    const [impactLevers, setImpactLevers] = useState([]);
    const [outcomes, setOutcomes] = useState([]);
    const [initialized, setInitialized] = useState(false);
    const [lastPipeline, setLastPipeline] = useState(null);
    const [lastSuccessPipeline, setLastSuccessPipeline] = useState(null);
    const [status, setStatus] = useState('');
    const [recalculate, setRecalculate] = useState(false);
    const [anchorPosition, setAnchorPosition] = useState(null);
    const open = Boolean(anchorPosition);
    const [segments, setSegments] = useState([]);
    const {data} = useInit({
        datasetId: isPDP ? '' : datasetId,
    });

    useEffect(() => {
        console.log(data)
        if (data && data.data) {
            setFilters({
                ...filters,
                meanFeature: data.data.opportunities.mean_feature.id,
                outcome: data.data.opportunities.selected_outcome.id
            })
            setMeanFeature(data.data.opportunities.mean_feature.name)
            const levers = data.data.opportunities.impact_levers.map((lever, index) => {
                return {
                    id: index,
                    name: lever.name,
                    value: lever.id,
                };
            })
            levers.push({
                id: 'all',
                name: 'All impact levers',
                value: 'all',
            })
            setImpactLevers(levers)
            setOutcomes(data.data.opportunities.outcomes.map((outcome, index) => {
                return {
                    id: index,
                    name: outcome.name,
                    value: outcome.id,
                };
            }));

            const availableSegments = data.data.opportunities.segments.map((segment, index) => ({
                id: index,
                name: segment.name,
                value: segment.id,
            }));
            availableSegments.unshift({
                id: 'all',
                name: 'All segments',
                value: 'all',
            });
            setSegments(availableSegments);

            setInitialized(true);
        }
    }, [data])

    useEffect(() => {
        if (datasetId !== selectedDatasetId) {
            setFilters({
                impactLever: "",
                outcome: "",
                show: 20,
                meanFeature: ""
            });
            setSegment('');
            setSegmentFeature('');
        }
    }, [selectedDatasetId])

    const openDrilldown = (value) => {
        setSegment(value.point.segment);
        setSegmentFeature(value.point.segment_feature);
        setLever(value.point.impact_lever.id);
        setAnchorPosition({top: value.clientY, left: value.clientX})
    }

    const reset = () => {
        setSegment('');
        setSegmentFeature('');
        setLever('');
        setAnchorPosition(null);
    }

    const handleChangeImpactLeaver = (impactLever) => {
        setFilters({
            ...filters,
            impactLever
        })
    }

    const handleClearImpactLeaver = () => {
        setFilters({
            ...filters,
            impactLever: 'all'
        })
    }

    const handleChangeOutcome = (outcome) => {
        if (data && data.data){
            const selectedOutcome = outcome || data.data.opportunities?.selected_outcome?.id || '';

            setFilters({
                ...filters,
                outcome: selectedOutcome
            });

            dispatch(
              notifySelectedOutcome(outcome)
            );
        }
    };

    const handleChangeShow = (show) => {
        setFilters({
            ...filters,
            show
        })
    }

    const handleChangeSegment = (segment) => {
        setFilters({
            ...filters,
            segment
        });
    };

    const checkPipelineStatus = (pipeline, status) => {
        return pipeline && pipeline.pipeline_status === status;
    }

    const triggerPipeline = () => {
        mutateAsync({datasetId, req: {}}).then(() => {
            setRecalculate(false)
        })
    }

    const isOpportunityAvailable = () => {
        if (data && data.data && data.data.opportunities.outcomes) {
            return data.data.opportunities.outcomes.length === 0
                || data.data.opportunities.impact_levers.length === 0 || data.data.opportunities.mean_feature.id === ''
        } else {
            return false;
        }
    }

    if (!initialized) {
        return (
            <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: containerHeight}}>
                <Grid item>
                    <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
                </Grid>
            </Grid>
        );
    }

    if (!datasetId && !isPDP) {
        return <AlertCard severity={'info'} height={containerHeight - 24}
                          message={'Please select a dataset for opportunities results !'}/>;
    }

    return (
        <Grid container flexDirection="column" flexWrap="nowrap" spacing={2}>
            <Grid item container spacing={2}>

                {checkPipelineStatus(lastPipeline, 'RUNNING') &&
                    <Grid item xs={12}>
                        <AlertCard severity={'info'} height={'auto'}
                                   marginRight={0}
                                   message={<><Link
                                       to={`${ROUTE_CONFIGURE_PIPELINES}?pipeline_id=${lastPipeline.pipeline_run_id}`}>Pipeline
                                       run</Link>
                                       &nbsp; on {format(parseISO(lastPipeline.pipeline_start_timestamp), 'Pp')} still
                                       in progress.</>}/>
                    </Grid>
                }

                {checkPipelineStatus(lastPipeline, 'ERROR') &&
                    <Grid item xs={12}>
                        <AlertCard severity={'error'} height={'auto'}
                                   marginRight={0}
                                   message={<>Last <Link
                                       to={`${ROUTE_CONFIGURE_PIPELINES}?pipeline_id=${lastPipeline.pipeline_run_id}`}>pipeline
                                       run</Link>
                                       &nbsp;on {format(parseISO(lastPipeline.pipeline_start_timestamp), 'Pp')} failed.</>}/>
                    </Grid>
                }

                {status === 'error' &&
                    <Grid item xs={12}>
                        {isPDP ? <AlertCard severity="error" height={'auto'} marginRight={0}
                                            message={"Opportunities data not available. Please contact administrator !"}/> :
                            <AlertCard severity="error"
                                       height={'auto'}
                                       marginRight={0}
                                       message={<>Please <Link
                                           to={ROUTE_DATASETS_CONFIGURE.replace(':datasetId', datasetId)}>configure
                                           the dataset</Link> for opportunities results !</>}/>}
                    </Grid>
                }

                {recalculate && <Grid item xs={12} container sx={{paddingTop: '0 !important'}}>
                    <Grid sx={{width: '100%', py: '0.5em',}}>
                        <AlertCard severity={'info'}
                                   marginRight={0}
                                   height={'auto'}
                                   action={
                                       <Button color="inherit" size="small" onClick={triggerPipeline}>
                                           Run
                                       </Button>
                                   }
                                   message={<>Opportunities data not matches with current dataset configurations.</>}/>
                    </Grid>
                </Grid>
                }

                {isOpportunityAvailable() ? <Grid item xs={12}><AlertCard severity="error"
                                                                          height={'auto'}
                                                                          marginRight={0}
                                                                          message={<>Please <Link
                                                                              to={ROUTE_DATASETS_CONFIGURE.replace(':datasetId', datasetId)}>configure
                                                                              the dataset</Link> for opportunities
                                                                              results
                                                                              !</>}/></Grid> : <>

                    <Grid item container xs={12} spacing={4} justifyContent='space-between'>
                        {!isPDP && <Grid item xs={4} container spacing={0.5}>
                            <Grid item xs={12}>
                                <DatasetSelector/>
                            </Grid>
                        </Grid>}
                        <Grid item xs={8}>
                            <Filters filters={filters} impactLevers={impactLevers} outcomes={outcomes} segments={segments}
                                     handleChangeSegment={handleChangeSegment}
                                     handleChangeImpactLeaver={handleChangeImpactLeaver}
                                     handleClearImpactLeaver={handleClearImpactLeaver}
                                     handleChangeOutcome={handleChangeOutcome}
                                     handleChangeShow={handleChangeShow}/>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Card sx={{
                            padding: 2,
                            height: '100%'
                        }}>
                            <OpportunitiesView
                                height={containerHeight - (status === 'error' || checkPipelineStatus(lastPipeline, 'RUNNING') || checkPipelineStatus(lastPipeline, 'ERROR') || recalculate ? 176 : 120)}
                                redraw={segment + segmentFeature + lever}
                                filters={filters}
                                outcomes={outcomes}
                                impactLevers={impactLevers}
                                xLabel={meanFeature}
                                setLastPipeline={setLastPipeline}
                                setLastSuccessPipeline={setLastSuccessPipeline}
                                initialized={initialized}
                                setStatus={setStatus}
                                setRecalculate={setRecalculate}
                                handleChangeImpactLeaver={handleChangeImpactLeaver}
                                openDrilldown={openDrilldown}/>
                        </Card>
                    </Grid>

                    {open && <OpportunitiesDrilldown
                        outcome={filters.outcome}
                        segment={segment}
                        lever={lever}
                        open={open}
                        anchorPosition={anchorPosition}
                        segmentFeature={segmentFeature} onclose={reset}/>}
                </>}
            </Grid>
        </Grid>
    )
}

export default RankedOpportunities;
