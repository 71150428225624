import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import useDatasets from 'hooks/datasets/use-datasets';
import {ROUTE_DATASETS_CONFIGURE, ROUTE_INVESTIGATE_TRENDS} from 'pages/constants';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import useDatasetDelete from 'hooks/datasets/use-dataset-delete';
import ConfirmationDialog from 'components/elements/ConfirmationDialog';
import InfiniteLoaderList from 'components/elements/InfiniteLoadList';
import AlertCard from 'components/elements/AlertCard';
import {notifyDatasetSelect} from 'state/app';

import DatasetCard from './dataset-card';

const DatasetsList = ({
                          orgId,
                          height,
                          filters = {
                              search: '',
                              status: '',
                              type: ''
                          },
                          uploadOpen
                      }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [flattenedDatasets, setFlattenedDatasets] = useState([]);

    const {data, isFetchingNextPage, hasNextPage, fetchNextPage, isError, status, refetch} = useDatasets({
        orgId,
        filters
    });
    const {mutateAsync} = useDatasetDelete();

    const navigate = useNavigate();
    const [datasetDeleting, setDatasetDeleting] = useState(null);
    const [datasetSelecting, setDatasetSelecting] = useState(null);

    const handleDeleteClose = () => {
        setDatasetDeleting(null);
    };

    const handleSelectClose = () => {
        setDatasetSelecting(null);
    };

    const handleDatasetConfigure = (e, dataset) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(ROUTE_DATASETS_CONFIGURE.replace(':datasetId', dataset.dataset_id));
    };

    const handleDelete = (e, dataset) => {
        e.preventDefault();
        e.stopPropagation();
        setDatasetDeleting(dataset)
    }

    const handleDataset = (e, dataset) => {
        e.preventDefault();
        e.stopPropagation();
        setDatasetSelecting(dataset)
    }

    const onConfirmDelete = (dataset) => {
        mutateAsync({datasetId: dataset.dataset_id}).then(() => {
            refetch()
        })
        setDatasetDeleting(null)
    }

    const onConfirmSelect = (dataset) => {
        dispatch(notifyDatasetSelect(dataset.dataset_id));
        setDatasetSelecting(null);

        // navigate(ROUTE_INVESTIGATE_TRENDS);
    }

    useEffect(() => {
        if (data) {
            setFlattenedDatasets(
                data.pages.reduce((accum, curr) => {
                    return [...accum, ...curr.data];
                }, []))
        }
    }, [data])

    useEffect(()=>{
        if(!uploadOpen){
            refetch()
        }
    },[uploadOpen])

    if (status === 'loading') {
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: height - 24}}>
            <Grid item>
                <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
            </Grid>
        </Grid>
    }

    if (status === 'error') {
        return <AlertCard severity={'error'} height={height - 24} message={'Something went wrong !'}/>;
    }

    if (flattenedDatasets) {
        if (flattenedDatasets.length > 0) {
            return (
                <>
                    <InfiniteLoaderList
                        height={height - 24}
                        data={flattenedDatasets}
                        isFetchingNextPage={isFetchingNextPage}
                        hasNextPage={hasNextPage}
                        fetchNextPage={fetchNextPage}
                        isError={isError}
                        itemTemplate={({style, data, index}) => <DatasetCard data={data} style={style} index={index}
                                                                             handleDelete={handleDelete}
                                                                             handleSelect={handleDataset}
                                                                             handleConfigure={handleDatasetConfigure}/>}
                    />
                    <ConfirmationDialog open={datasetDeleting}
                                        title={t('feature.datasets.management.delete.dialog.title')}
                                        onClose={handleDeleteClose}
                                        onConfirm={onConfirmDelete}
                                        context={t('feature.datasets.management.delete.dialog.context', {dataset: datasetDeleting ? datasetDeleting.name : ''})}/>
                    <ConfirmationDialog open={datasetSelecting}
                                        title={t('feature.datasets.management.select.dialog.title')}
                                        onClose={handleSelectClose}
                                        onConfirm={onConfirmSelect}
                                        context={t('feature.datasets.management.select.dialog.context', {dataset: datasetSelecting ? datasetSelecting.name : ''})}/>
                </>
            );
        } else {
            return <AlertCard severity={'info'} height={height - 24} message={'No data available !'}/>;
        }
    }
};

export default DatasetsList;
