import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import { Step, StepLabel, Stepper } from '@mui/material';
import Button from '@mui/material/Button';

import useRunAnalysis from 'hooks/custom-trial/use-run-analysis';
import useInitAnalysis from 'hooks/results/use-init-analysis';
import PageContainer from 'components/elements/PageContainer';
import { ROUTE_EVALUATE_RESULTS } from 'pages/constants';
import OpenInNewIcon from 'components/icons/OpenInNewIcon';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import AlertCard from 'components/elements/AlertCard';
import { notifyAddTag } from 'state/app';

import TreatmentConfigurationForm from './treatment-configuration-form';
import IndividualRunAnalysisForm from './individual-run-analysis-form';
import ConcurrentRunAnalysisForm from './concurrent-run-analysis-form';
import TreatmentRunAnalysisForm from './treatment-run-analysis-form';
import DosageRunAnalysisForm from './dosage-run-analysis-form';

const steps = [
  'Treatment Type',
  'Run Analysis',
];

const RunAnalysis = () => {
  const { t } = useTranslation();

  return (
    <PageContainer pageTitle={t('Run Analysis')}>
      <RunAnalysisPage />
    </PageContainer>
  );
};

const RunAnalysisPage = ({ containerHeight }) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const inputRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const datasetId = useSelector((state) => state.app.dataset);
  const initData = useSelector((state) => state.app.initData);

  const [outcomes, setOutcomes] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [request, setRequest] = useState({
    treatment_type: 'individual_analysis',
  });
  const [activeStep, setActiveStep] = useState(0);
  const [tags, setTags] = useState([]);

  const { mutateAsync, status: runStatus } = useRunAnalysis();

  useEffect(() => {
    if (initData) {
      setTags(
        initData['trials']['tags'].map((tag, index) => {
          return {
            id: tag['tag'],
            name: tag['tag'],
            value: tag['tag'],
            color: tag['color'],
          };
        }),
      );
    }
  }, [initData]);

  const goToResults = () => {
    navigate(ROUTE_EVALUATE_RESULTS);
  };

  const { data, status, isError } = useInitAnalysis({ datasetId });

  useEffect(() => {
    if (data) {
      if (data.outcomes) {
        setOutcomes(data.outcomes);
      }

      if (data.treatments) {
        setTreatments(data.treatments);
      }
    }
  }, [data]);

  useEffect(() => {
    setActiveStep(0);
    setRequest({
      treatment_type: 'individual_analysis',
    });
  }, [datasetId]);

  const handleSubmit = () => {
    formRef.current.handleSubmit();
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    const req = {
      ...request,
      ...formRef.current.values,
    };
    setRequest(req);
    setActiveStep(activeStep - 1);
  };

  const onSubmit = (values) => {
    if (activeStep === 0) {
      setRequest({
        ...request,
        ...values,
      });
      handleNext();
    } else if (activeStep === 1 && (request && request['treatment_type'] === 'individual_analysis')) {
      const req = {
        program_name: request['experiment_name'],
        program_category: '',
        custom_tag: request['tags'],
        random_state: !request['random_state'] ? -1 : +request['random_state'],
        dataset_id: datasetId,
        scenario_type: '',
        experiment: {
          treatment_dimension_matrix: values['treatment_dimension_matrix'],
        },
      };
      mutateAsync({ req }).then((res) => {
        if (res['data'] && res['data']['payload'] && res['data']['payload']['tags']) {
          dispatch(notifyAddTag(res['data']['payload']['tags']));
        }
        goToResults();
      });
    } else if (activeStep === 1 && (request && request['treatment_type'] === 'concurrent_analysis')) {
      const req = {
        program_name: request['experiment_name'],
        program_category: '',
        custom_tag: request['tags'],
        random_state: !request['random_state'] ? -1 : +request['random_state'],
        dataset_id: datasetId,
        scenario_type: 'with_concurrent_treatments',
        experiment: {
          treatment_dimension_matrix: values['treatment_dimension_matrix'],
        },
      };
      mutateAsync({ req }).then((res) => {
        if (res['data'] && res['data']['payload'] && res['data']['payload']['tags']) {
          dispatch(notifyAddTag(res['data']['payload']['tags']));
        }
        goToResults();
      });
    } else if (activeStep === 1 && (request && request['treatment_type'] === 'dosage_analysis')) {
      const req = {
        program_name: request['experiment_name'],
        program_category: '',
        custom_tag: request['tags'],
        random_state: !request['random_state'] ? -1 : +request['random_state'],
        dataset_id: datasetId,
        scenario_type: 'with_dosage',
        experiment: {
          treatment_dimension_matrix: values['treatment_dimension_matrix'],
        },
      };
      mutateAsync({ req }).then((res) => {
        if (res['data'] && res['data']['payload'] && res['data']['payload']['tags']) {
          dispatch(notifyAddTag(res['data']['payload']['tags']));
        }
        goToResults();
      });
    } else if (activeStep === 1 && (request && request['treatment_type'] === 'treatment_from_csv')) {
      let treatment_dimension_matrix = [];
      let row;
      row = {
        TreatmentID: 'Treatment',
        Description: 'Treatment',
      };

      for (const outcome of outcomes) {
        if (values.outcome.includes(outcome.id)) {
          row[outcome.id] = 1;
        } else {
          row[outcome.id] = 0;
        }
      }
      treatment_dimension_matrix.push(row);

      const req = {
        program_name: request['experiment_name'],
        program_category: '',
        custom_tag: request['tags'],
        random_state: !request['random_state'] ? -1 : +request['random_state'],
        dataset_id: datasetId,
        scenario_type: '',
        treatment_file_path: request['treatment_file_path'],
        experiment: {
          treatment_dimension_matrix,
          options: {},
        },
      };
      mutateAsync({ req }).then((res) => {
        if (res['data'] && res['data']['payload'] && res['data']['payload']['tags']) {
          dispatch(notifyAddTag(res['data']['payload']['tags']));
        }
        goToResults();
      });
    }
  };

  const nextLabel = () => {
    if (activeStep === 0) {
      return 'Next';
    } else {
      return 'Save';
    }
  };

  const handleAddTag = (tag) => {
    setTags([...tags, {
      id: tag,
      name: tag,
      value: tag,
      color: '#fff',
    }]);
  };

  if (status === 'loading') {
    return <Grid container justifyContent='center' alignItems='center'
                 sx={{ width: '100%', height: containerHeight - 24 }}>
      <Grid item>
        <LoaderSpinner type='Bars' color='#175da8' secondaryColor={'#6abed5'} height={70}
                       width={70} />
      </Grid>
    </Grid>;
  }

  if (isError) {
    return <AlertCard severity={'error'} height={containerHeight - 24} message={'Something went wrong !'} />;
  }

  return <Grid container spacing={2}>
    <Grid container xs={12} item>
      <Grid item container spacing={1} sx={{ '&.MuiGrid-root': { width: 'unset' } }}>
        <Grid item>
          <Grid
            item
            container
            alignItems='center'
            width='unset'
            sx={{ cursor: 'pointer' }}
            onClick={goToResults}
          >
            <Grid item>
              <Typography>Results</Typography>
            </Grid>
            <Grid item sx={{ pl: '0.3em', '&.MuiGrid-root': { display: 'flex' } }}>
              <OpenInNewIcon />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    <Grid item container xs={12} justifyContent={'center'} ml={'1em'}>
      <Grid container spacing={2} item sx={{ pt: '1em' }} xs={12}>
        <Paper sx={{ width: '100%', p: '2em' }}>
          <Grid container spacing={2}>
            <Grid item container xs={12}>
              <Grid item container xs={12} justifyContent='flex-end' alignItems='center' pt={0}>
                <Grid item xs={6} container justifyContent='flex-end' spacing={2} pb={2}>
                  {activeStep > 0 && <Grid item pt={0}>
                    <Button onClick={handleBack}
                            startIcon={<ArrowBackIcon />}
                            disabled={runStatus === 'loading'}
                            variant={'contained'}>
                      {t('Back')}
                    </Button>
                  </Grid>}
                  <Grid item pt={0}>
                    <Button onClick={handleSubmit}
                            disabled={runStatus === 'loading'}
                            startIcon={nextLabel() === 'Save' ? <SaveIcon /> : <ArrowForwardIcon />}
                            variant={'contained'}>
                      {t(nextLabel())}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ marginBottom: '2em' }}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>

            <Box sx={{
              paddingLeft: '32px !important',
              height: `${containerHeight - 292}px`,
              overflowY: 'auto',
              overflowX: 'hidden',
              width: '100%',
            }}>
              {activeStep === 0 &&
                <TreatmentConfigurationForm
                  formRef={formRef}
                  onSubmit={onSubmit}
                  request={request}
                  tags={tags}
                  treatments={treatments}
                  outcomes={outcomes}
                  handleAddTag={handleAddTag}
                  height={containerHeight - 40} />
              }

              {activeStep === 1 && (request && request['treatment_type'] === 'individual_analysis') &&
                <IndividualRunAnalysisForm
                  outcomes={outcomes}
                  treatments={treatments}
                  formRef={formRef}
                  onSubmit={onSubmit} />
              }
              {activeStep === 1 && (request && request['treatment_type'] === 'concurrent_analysis') &&
                <ConcurrentRunAnalysisForm
                  outcomes={outcomes}
                  treatments={treatments}
                  formRef={formRef}
                  request={request}
                  onSubmit={onSubmit} />
              }
              {activeStep === 1 && (request && request['treatment_type'] === 'dosage_analysis') &&
                <DosageRunAnalysisForm
                  outcomes={outcomes}
                  treatments={treatments}
                  formRef={formRef}
                  request={request}
                  onSubmit={onSubmit} />
              }
              {activeStep === 1 && (request && request['treatment_type'] === 'treatment_from_csv') &&
                <TreatmentRunAnalysisForm
                  outcomes={outcomes}
                  treatments={treatments}
                  formRef={formRef}
                  request={request}
                  height={containerHeight}
                  onSubmit={onSubmit} />
              }
            </Box>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  </Grid>;
};

export default RunAnalysis;