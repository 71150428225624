import React, {useEffect, useMemo, useState} from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {TextField} from '@mui/material';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import {getIn} from 'formik';

import LabeledCustomSelect from 'components/elements/LabeledCustomSelect';
import { Controller } from 'react-hook-form';

const CardRoot = styled(Box)(() => ({
    padding: '2px 0px 2px 0px',
    marginBottom: '4px',
    boxShadow: 'none',
    "&$expanded": {
        margin: "0px"
    }
}));

const EditableTextColumn = ({
                                name,
                                index,
                                value, // Value passed from React Hook Form
                                onChange, // onChange handler passed from React Hook Form
                                onBlur, // onBlur handler passed from React Hook Form
                                error, // Validation error message
                                touched // Whether the field has been touched
                            }) => {
    return (
      <TextField
        id={`features.${index}.${name}`}
        name={`features.${index}.${name}`}
        variant="outlined"
        fullWidth
        required
        value={value} // Controlled value from React Hook Form
        onChange={onChange} // React Hook Form's onChange handler
        onBlur={onBlur} // React Hook Form's onBlur handler
        error={Boolean(error)} // Show error if validation failed
        helperText={error} // Display error message
        size="small"
      />
    );
};


const EditableSwitchColumn = ({
                                  value,
                                  onChange,
                                  onBlur,
                                  name,
                                  index,
                                  error
                              }) => {

    return (
      <Switch
        name={`features.${index}.${name}`}
        checked={Boolean(value)}
        onChange={onChange}
        onBlur={onBlur}
        error={Boolean(error)}
      />
    );
};


const FeatureCardNew = ({
                          values=[],
                         touched, errors,control,row,index
                     }) => {
    const {t} = useTranslation();


    const dataTypes = [
        {
            name: 'Categorical',
            value: 'CATEGORICAL',
            id: 'CATEGORICAL'
        },
        {
            name: 'Numerical',
            value: 'NUMERICAL',
            id: 'NUMERICAL'
        },
        {
            name: 'Boolean',
            value: 'BOOLEAN',
            id: 'BOOLEAN'
        },
        {
            name: 'Date',
            value: 'DATE',
            id: 'DATE'
        }

    ]

    const trendOperations = [
        {
            id: 'MEAN',
            name: 'Mean',
            value: 'mean'
        },
        {
            id: 'MEDIAN',
            name: 'Median',
            value: 'median',
        },
        {
            id: 'SUM',
            name: 'Sum',
            value: 'sum'
        },
        {
            id: 'EXCLUDE',
            name: 'Exclude',
            value: ''
        }
    ]

    return <CardRoot key={index}>
            {row && <Grid container>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={3}>
                        <Typography sx={{
                            maxWidth: '200px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>{row.feature}</Typography>
                      <Controller
                        name={`rows[${row.feature}].feature`}
                        control={control}
                        defaultValue={row.feature}
                        render={({ field }) => <input type="hidden" {...field} />}
                      />
                      <Controller
                        name={`rows[${row.feature}].detected_data_type`}
                        control={control}
                        defaultValue={row.detected_data_type}
                        render={({ field }) => <input type="hidden" {...field} />}
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                        <Controller
                          name={`rows[${row.feature}].feature_name`}
                          control={control}
                          defaultValue={row.feature_name}
                          render={({ field, }) => (
                            <EditableTextColumn
                              {...field} // Passing React Hook Form's field props
                              index={row.feature}
                              name="feature_name"
                              error={errors?.features?.[row.feature]?.feature_name?.message} // Access the error message
                              touched={touched?.features?.[row.feature]?.feature_name} // Check if touched
                            />
                          )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Controller
                          name={`rows[${row.feature}].data_type`}
                          control={control}
                          defaultValue={row.data_type}
                          render={({ field, }) => (
                            <LabeledCustomSelect
                              {...field}
                              id={`features.${row.feature}.${'data_type'}`}
                              selectItems={dataTypes.filter(type=>{
                                  return !(row['detected_data_type'] === 'NUMERICAL' && ['BOOLEAN','DATE'].includes(type.value))
                              })}
                              disabled={row['detected_data_type'] !== 'NUMERICAL'}
                              onClear={() => field.onChange('')}
                              value={field.value}
                              onBlur={field.onBlur}
                              onChange={(e) => field.onChange(e.target.value)}
                              error={Boolean(errors?.features?.[row.feature]?.data_type?.message)}
                              helperText={errors?.features?.[row.feature]?.data_type?.message}
                              size="small"
                            />
                          )}
                        />
                    </Grid>
                    <Grid item xs={1.5} container justifyContent={'center'}>
                        <Grid item>
                            <Controller
                              name={`rows[${row.feature}].block_from_ppsm`}
                              control={control}
                              defaultValue={row.block_from_ppsm}
                              render={({ field, }) => (
                                <EditableSwitchColumn
                                  {...field} // Passing React Hook Form's field props
                                  index={row.feature}
                                  name="block_from_ppsm"
                                  error={errors?.features?.[row.feature]?.block_from_ppsm?.message} // Access the error message
                                  touched={touched?.features?.[row.feature]?.block_from_ppsm} // Check if touched
                                />
                              )}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Controller
                          name={`rows[${row.feature}].trend_operation`}
                          control={control}
                          defaultValue={row.trend_operation}
                          render={({ field, }) => (
                            <LabeledCustomSelect
                              {...field}
                              id={`features.${row.feature}.${'trend_operation'}`}
                              selectItems={trendOperations}
                              disabled={row['detected_data_type'] === 'CATEGORICAL' || row['detected_data_type'] === 'DATE'}
                              onClear={() => field.onChange('')}
                              value={field.value}
                              onBlur={field.onBlur}
                              onChange={(e) => field.onChange(e.target.value)}
                              error={Boolean(errors?.features?.[row.feature]?.trend_operation?.message)}
                              helperText={errors?.features?.[row.feature]?.trend_operation?.message}
                              size="small"
                              showClear={true}
                            />
                          )}
                        />
                    </Grid>
                </Grid>
            </Grid>
            }
        </CardRoot>
}

export default FeatureCardNew;