import React from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';

import DebouncedSearchField from 'components/elements/DebouncedSearchField';

const FeatureFilters = ({
                            handleSearch = () => {
                            }
                        }) => {
    const {t} = useTranslation();

    return (
        <Grid item xs={4} md={4} container flexWrap="nowrap" justifyContent="flex-start" spacing={1}>
            <Grid item xs={12} xl={5}>
                <DebouncedSearchField onSearch={handleSearch}
                                      fontSize={'0.875rem'}
                                      placeholder={t('feature.dataset.configure.features.filters.search.placeholder')}/>
            </Grid>
        </Grid>
    );
};

export default FeatureFilters;
