import React, {useEffect, useRef, useState} from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {useParams} from "react-router-dom";
import * as Yup from 'yup';
import { FieldArray, Formik, useFormikContext } from 'formik';
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {saveAs} from 'file-saver';
import Papa from 'papaparse';
import Typography from '@mui/material/Typography';

import useTimeUnitsConfiguration from 'hooks/datasets/use-dataset-configuration/time-units-configs';
import useDownloadDatasetTimeUnits from 'hooks/datasets/use-dataset-download/time-units';
import AlertCard from 'components/elements/AlertCard';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import CustomAutocomplete from 'components/elements/CustomAutocomplete';
import CustomInputRow from 'components/elements/CustomInputRow';

import TimeDataList from './time-data-list';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import timeUnits from 'hooks/datasets/use-dataset-download/time-units';

const TimeDataConfigurationStep = ({formRef, dataset, height, onSubmit, configs, handleOriginalConfigs}) => {
    const {t} = useTranslation();
    const {datasetId} = useParams();
    const inputRef = useRef();

    const [timeUnitsUploaded, setTimeUnitsUploaded] = useState(false);
    const [timeUnitsConfigs, setTimeUnitsConfigs] = useState(null);

    const [timeUnitType, setTimeUnitType] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [generating, setGenerating] = useState(false);
    const [showMaxUnitsAlert, setShowMaxUnitsAlert] = useState(false);
    const [tooManyValues, setTooManyValues] = useState(false);

    const {data, status} = useTimeUnitsConfiguration({
        datasetId,
        enabled: !timeUnitsUploaded
    });
    const {mutateAsync} = useDownloadDatasetTimeUnits();

    const validationSchema = Yup.object().shape({
        date_column: Yup.string(),
        trend_diff_timeunit_lower: Yup.string().test({
            message: t('Trend time unit lower is required'),
            test: function (value, ctx) {
                return !(!(!!value) && !!ctx.parent.date_column && ctx.options?.context?.time_unit_type !== 'INT')
            },
        }),
        trend_diff_timeunit_upper: Yup.string().test({
            message: t('Trend time unit upper is required'),
            test: function (value, ctx) {
                return !(!(!!value) && !!ctx.parent.date_column && ctx.options?.context?.time_unit_type !== 'INT')
            },
        }),
        start_value: Yup.string().test({
            message: t('Start value is required'),
            test: function (value, ctx) {
                return !(!(!!value) && !!ctx.parent.date_column && ctx.options?.context?.time_unit_type === 'INT')
            },
        }),
        end_value: Yup.string().test({
            message: t('End value is required'),
            test: function (value, ctx) {
                return !(!(!!value) && !!ctx.parent.date_column && ctx.options?.context?.time_unit_type === 'INT')
            },
        }),
        time_unit_type: Yup.string().test({
            message: t('Time unit is required'),
            test: function (value, ctx) {
                return !!value
            },
        }),
        time_units: Yup.array()
          .of(
            Yup.object().shape({
                id: Yup.string().required(t('feature.dataset.configure.time_data.time_units.id.required')),
                start_time: Yup.date().nullable().test({
                    message: t('feature.dataset.configure.time_data.time_units.start_time.required'),
                    test: function(value, ctx) {
                        const timeUnitType = ctx.options?.context?.time_unit_type || '';
                        // // Only require start_time if not using INT | STRING features
                        if (timeUnitType === 'INT' || timeUnitType === 'MANUAL' || timeUnitType === 'STRING') return true;

                        // Ensure end_time is a valid date
                        return !(!value || isNaN(new Date(value).getTime()));


                    }
                }),
                end_time: Yup.date().nullable().test({
                    message: t('feature.dataset.configure.time_data.time_units.end_time.required'),
                    test: function (value, ctx) {
                        // Only validate end_time for non-INT or non-STRNG features
                        const timeUnitType = ctx.options?.context?.time_unit_type || '';
                        if (timeUnitType === 'INT' || timeUnitType === 'MANUAL' || timeUnitType === 'STRING') return true;
                        // Ensure end_time is a valid date
                        if (!value || isNaN(new Date(value).getTime())) {
                            return false;
                        }

                        // If start_time exists, ensure end_time is after or equal to start_time
                        const startTime = ctx.parent.start_time;
                        if (startTime && !isNaN(new Date(startTime).getTime())) {
                            return new Date(value) >= new Date(startTime);
                        }

                        return true; // Valid if start_time is not provided
                    },
                }),

                value: Yup.string() // Not required, used for INT features
            })
          ).test({
              message: t('Add at least 2 time units'),
              test: function (value, ctx) {
                  return !(!!ctx.parent.date_column && value.length < 2)
              },
          }),
    });

    useEffect(() => {
        if (data && configs && !configs['time_unit_type']) {
            setTimeUnitType(data.data['time_unit_type']??'MANUAL');
            const configs = {
                ...data.data,
                trend_diff_timeunit_start:data.data['trend_diff_timeunit_start']?new Date(data.data['trend_diff_timeunit_start']).toISOString() : '',
                trend_diff_timeunit_end:data.data['trend_diff_timeunit_end']?new Date(data.data['trend_diff_timeunit_end']).toISOString() : '',
                time_units: data.data['time_units'].map((t, index) => {
                      return {
                          ...t,
                          order: index,
                          start_time: t.start_time ? new Date(t.start_time).toISOString() : '',
                          end_time: t.end_time ? new Date(t.end_time).toISOString() : '',
                      }
                  }
                )

            }
            setTimeUnitsConfigs(configs)
            handleOriginalConfigs(configs)
        }
    }, [data])

    useEffect(() => {
        if (configs && configs['time_units']) {
            setTimeUnitType(configs['time_unit_type']??'MANUAL');
            setTimeUnitsConfigs({
                time_units: configs['time_units'],
                trend_diff_timeunit_lower: configs['trend_diff_timeunit_lower'],
                trend_diff_timeunit_upper: configs['trend_diff_timeunit_upper'],
                date_column: configs['date_column'],
                time_unit_type: configs['time_unit_type'],
                trend_diff_timeunit_start: configs['trend_diff_timeunit_start'],
                trend_diff_timeunit_end: configs['trend_diff_timeunit_end'],
                start_value: configs['start_value'],
                end_value: configs['end_value']
            })
        }
    }, [configs])

    const handleDownload = () => {
        mutateAsync({datasetId}).then((res) => {
            saveAs(res.data, res.filename);
        });
    }

    const handleUpload = () => {

        setTimeUnitType('MANUAL');

        if (formRef && formRef.current) {
            formRef.current.setFieldValue('time_unit_type', 'MANUAL');
            formRef.current.setFieldValue('date_column', '');
            formRef.current.setFieldValue('trend_diff_timeunit_lower', '');
            formRef.current.setFieldValue('trend_diff_timeunit_upper', '');
            formRef.current.setFieldValue('start_value', '');
            formRef.current.setFieldValue('end_value', '');
            formRef.current.setFieldValue('time_units', []);
        }

        inputRef.current.click();
    }

    const processFile = (event) => {
        const fileUploaded = event.target.files[0];
        Papa.parse(fileUploaded, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                setTimeUnitsUploaded(true)
                setTimeUnitsConfigs({
                    ...timeUnitsConfigs,
                    time_unit_type: 'MANUAL',
                    date_column: '',
                    trend_diff_timeunit_lower: '',
                    trend_diff_timeunit_upper: '',
                    time_units: results.data.map(t => {
                          return {
                              ...t,
                              order: Number(t['order'])
                          }
                      }
                    ),
                })
                if (formRef && formRef.current) {
                    formRef.current.setFieldValue('time_unit_type', 'MANUAL');
                    formRef.current.setFieldValue('date_column', '');
                    formRef.current.setFieldValue('trend_diff_timeunit_lower', '');
                    formRef.current.setFieldValue('trend_diff_timeunit_upper', '');
                }

                resetFileInput()
            },
        });
    }

    const resetFileInput = () => {
        inputRef.current.value = "";
    }

    const handleSubmit = (values) => {
        const req = {
            "trend_diff_timeunit_lower": values.trend_diff_timeunit_lower,
            "trend_diff_timeunit_upper": values.trend_diff_timeunit_upper,
            "date_column": values.date_column,
            "start_value": values.start_value,
            "end_value": values.end_value,
            "trend_diff_timeunit_start": values.trend_diff_timeunit_start,
            "trend_diff_timeunit_end": values.trend_diff_timeunit_end,
            "time_unit_type": values.time_unit_type,
            time_units: values['time_units'].map((t, index) => {
                  return {
                      ...t,
                      order: index,
                      start_time: t.start_time ? new Date(t.start_time).toISOString() : null,
                      end_time: t.end_time ? new Date(t.end_time).toISOString() : null,
                  }
              }
            )
        }
        onSubmit(req)
    }

    const generateIntTimeUnits = (startValue, endValue, featureColumn, setFieldValue) => {
        return new Promise((resolve) => {
            setGenerating(true);
            if (!startValue || !endValue || !featureColumn) {
                setGenerating(false);
                resolve([]);
                return;
            }
            // Get the feature column details
            const selectedColumn = dataset.columns.find(col => col.id === featureColumn);
            if (!selectedColumn || !selectedColumn.distinct_vals || selectedColumn.distinct_vals.length === 0) {
                setGenerating(false);
                resolve([]);
                return;
            }
            let filteredValues
            const allNumeric = selectedColumn.distinct_vals.every(val => !isNaN(Number(val)));
            if (allNumeric) {
                filteredValues = selectedColumn.distinct_vals
                  .sort((a, b) =>Number(a) - Number(b))
                  .filter(val => {
                      const numVal = Number(val);
                      return !isNaN(numVal) && numVal >= Number(startValue) && numVal <= Number(endValue);
                  })
            } else {
                filteredValues = selectedColumn.distinct_vals
                  .sort((a, b) =>a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
                  .filter(val => {
                      return val >= startValue && val <= endValue;
                  })
            }

            // Create time units
            const timeUnits = filteredValues.map((val, index) => ({
                id: `${val}`,
                start_time: null,
                end_time: null,
                order: index
            }));

            if (timeUnits.length > 0) {
                setFieldValue('trend_diff_timeunit_lower', timeUnits[0].id);
                setFieldValue('trend_diff_timeunit_upper', timeUnits[timeUnits.length - 1].id);
            }

            setGenerating(false);
            resolve(timeUnits);
        });
    };

    const handleValueChange = (type, value, setFieldValue, values) => {
        if (type === 'start') {
            setFieldValue('start_value', value ? value.id : '');
        } else {
            setFieldValue('end_value', value ? value.id : '');
        }

        const currentStartValue = type === 'start' ? (value ? value.id : '') : values.start_value;
        const currentEndValue = type === 'end' ? (value ? value.id : '') : values.end_value;

        if (currentStartValue && currentEndValue && (timeUnitType === 'INT' || timeUnitType === 'STRING') && values.date_column) {

            generateIntTimeUnits(currentStartValue, currentEndValue, values.date_column, setFieldValue)
              .then(units => {
                  setFieldValue('time_units', units);
              })
              .catch(error => {
                  console.error('Error generating INT time units:', error);
              });
        }
    };


    const generateTimeUnits = async (start, end, type,setFieldValue) => {
        setGenerating(true);
        if (!start || !end) return [];

        const timeUnits = [];
        let currentDate = new Date(start);
        let index = 0;
        const endDate = new Date(end);

        const padNumber = (num) => String(num).padStart(2, '0');

        const maxIterations = 150;
        let iterations = 0;

        while (currentDate < endDate && iterations < maxIterations) {
            let nextDate = new Date(currentDate);
            let id = '';

            try {
                switch (type) {
                    case 'DAILY':
                        nextDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1);
                        id = `${currentDate.getFullYear()}_${padNumber(currentDate.getMonth() + 1)}_${padNumber(currentDate.getDate())}`;
                        break;

                    case 'MONTHLY':
                        nextDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                        id = `${currentDate.getFullYear()}_${padNumber(currentDate.getMonth() + 1)}`;
                        break;

                    case 'QUARTERLY':
                        const quarter = Math.floor(currentDate.getMonth() / 3) + 1;
                        const quarterEndMonth = Math.floor(currentDate.getMonth() / 3) * 3 + 2;
                        const quarterEndDay = quarterEndMonth === 2 ? 31 : quarterEndMonth === 5 ? 30 : quarterEndMonth === 8 ? 30 : 31;
                        nextDate = new Date(currentDate.getFullYear(), quarterEndMonth, quarterEndDay);
                        id = `${currentDate.getFullYear()}_Q${quarter}`;
                        break;

                    case 'YEARLY':
                        nextDate = new Date(currentDate.getFullYear(), 11, 31);
                        id = `${currentDate.getFullYear()}`;
                        break;

                    default:
                        return [];
                }

                if (nextDate > endDate) {
                    nextDate = new Date(endDate);
                }

                if (nextDate > currentDate) {
                    timeUnits.push({
                        id: id,
                        start_time: new Date(Date.UTC(
                          currentDate.getFullYear(),
                          currentDate.getMonth(),
                          currentDate.getDate()
                        )).toISOString(),
                        end_time: new Date(Date.UTC(
                          nextDate.getFullYear(),
                          nextDate.getMonth(),
                          nextDate.getDate()
                        )).toISOString(),
                        order: index
                    });
                    index++;
                }

                currentDate = type === "DAILY" ? nextDate : new Date(nextDate.getFullYear(), nextDate.getMonth(), nextDate.getDate() + 1);
                iterations++;

            } catch (error) {
                console.error('Error generating time unit:', error);
                break;
            }
        }
        if (iterations >= maxIterations) {
            console.warn('Maximum iterations reached in generateTimeUnits');
            setShowMaxUnitsAlert(true);
        } else {
            setShowMaxUnitsAlert(false);
        }
        setGenerating(false);
        setFieldValue('trend_diff_timeunit_lower', timeUnits[0].id);
        setFieldValue('trend_diff_timeunit_upper', timeUnits[timeUnits.length - 1].id);
        return timeUnits;
    };

    const handleFeatureColumnChange = (value, setFieldValue) => {
        setFieldValue('trend_diff_timeunit_lower', '');
        setFieldValue('trend_diff_timeunit_upper', '');
        setFieldValue('start_value', '');
        setFieldValue('end_value', '');
        setFieldValue('date_column', value ? value.id : '');

        if (timeUnitType === 'INT' && value) {
            // Get the selected column
            const selectedColumn = dataset.columns.find(col => col.id === value.id);

            // Check if distinct_vals exists and has values
            if (!selectedColumn || !selectedColumn.distinct_vals || selectedColumn.distinct_vals.length === 0) {
                setTooManyValues(true);
            } else if (selectedColumn.distinct_vals.length > 150) {
                setTooManyValues(true);
            } else {
                setTooManyValues(false);
            }
        }
    };

    const getDistinctValue = (values)=>{
        if ((values['time_unit_type'] === 'INT' || values['time_unit_type'] === 'STRING') && values['date_column']) {
            // Get the selected column
            const selectedColumn = dataset.columns.find(col => col.id === values['date_column']);

            if (selectedColumn && selectedColumn.distinct_vals && selectedColumn.distinct_vals.length > 0) {
                return selectedColumn.distinct_vals.sort((a, b) => {
                      // Check if ALL values in the filtered list are numbers
                      const allNumeric = selectedColumn.distinct_vals.every(val => !isNaN(Number(val)));

                      if (allNumeric) {
                          // If all values are numbers, sort numerically
                          return Number(a) - Number(b);
                      } else {
                          // If any value is not a number, sort naturally
                          return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
                      }
                  });
            }else{
                return []
            }
        } else{
            return []
        }
    }

    // console.log(formRef.current?.values,timeUnitType,formRef.current?.errors)

    const handleTimeUnitTypeChange = (event, setFieldValue, values) => {

        const newType = event.target.value;
        setTimeUnitType(newType);
        setFieldValue('date_column', '');
        setFieldValue('trend_diff_timeunit_lower', '');
        setFieldValue('trend_diff_timeunit_upper', '');
        setFieldValue('trend_diff_timeunit_start', '');
        setFieldValue('trend_diff_timeunit_end', '');
        setFieldValue('time_units', []);
        setFieldValue('start_value', '');
        setFieldValue('end_value', '');
        setFieldValue('time_unit_type', newType);
        setStartDate(null);
        setEndDate(null);
        setTooManyValues(false)
    };

    const handleGenerate = (values,setFieldValue)=>{
        setGenerating(true);
        setTooManyValues(false);
        if (timeUnitType === 'MANUAL') {
            setFieldValue('time_units', values.time_units);
            setGenerating(false);
        } else if (values.trend_diff_timeunit_start && values.trend_diff_timeunit_end) {
            try {
                const startDate = new Date(values.trend_diff_timeunit_start);
                const endDate = new Date(values.trend_diff_timeunit_end);

                if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime()) && endDate > startDate) {
                    generateTimeUnits(startDate, endDate, timeUnitType,setFieldValue).then(values => {
                        setFieldValue('time_units', values);
                        if (values && values.length > 0) {
                            setFieldValue('trend_diff_timeunit_lower', values[0].id);
                            setFieldValue('trend_diff_timeunit_upper', values[values.length - 1].id);
                        }
                    });
                }
                setGenerating(false);
            } catch (error) {
                console.error('Error processing dates:', error);
                setGenerating(false);
            }
        } else {
            setStartDate(null);
            setFieldValue('trend_diff_timeunit_start', '');
            setEndDate(null);
            setFieldValue('trend_diff_timeunit_end', '');
            setFieldValue('time_units', []);
        }
        setGenerating(false);
    };

    const handleDateRangeChange = (type, date, setFieldValue) => {
        if (!date) {
            if (type === 'start') {
                setStartDate(null);
                setFieldValue('trend_diff_timeunit_start', '');
            } else {
                setEndDate(null);
                setFieldValue('trend_diff_timeunit_end', '');
            }
            return;
        }
        // Ensure we have a valid date object

        const validDate = date instanceof Date ? date : new Date(date);
        if (isNaN(validDate.getTime()) || validDate.getTime() < 0) {
            return;
        }
        if (type === 'start') {
            if (endDate && validDate.getTime() > endDate.getTime()) {
                return;
            }
            setStartDate(validDate);
            setFieldValue('trend_diff_timeunit_start', validDate.toISOString());
        } else {
            if (startDate && validDate.getTime() < startDate.getTime()) {
                return;
            }
            setEndDate(validDate);
            setFieldValue('trend_diff_timeunit_end', validDate.toISOString());
        }

        if (timeUnitType !== 'MANUAL') {
            setGenerating(true);
            const currentStartDate = type === 'start' ? validDate : startDate;
            const currentEndDate = type === 'end' ? validDate : endDate;
            if (currentStartDate && currentEndDate && currentEndDate > currentStartDate) {
                try {
                    generateTimeUnits(currentStartDate, currentEndDate, timeUnitType,setFieldValue).then(values => {
                          setFieldValue('time_units', values);
                          if (values && values.length > 0) {
                              setFieldValue('trend_diff_timeunit_lower', values[0].id);
                              setFieldValue('trend_diff_timeunit_upper', values[values.length - 1].id);
                          }
                      }
                    );
                } catch (error) {
                    setGenerating(false);
                }
            }
        }
        setGenerating(false);
    };

    if (status === 'error') {
        return <AlertCard severity={'error'} height={400} message={'Something went wrong !'}/>;
    }

    if (status === 'loading' || !timeUnitsConfigs) {
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', minHeight: 400}}>
            <Grid item xs={12} container justifyContent="center" spacing={2}>
                <Grid item xs={12} container justifyContent="center">
                    <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
                </Grid>
                <Grid item>
                    <Typography>Loading time data configurations</Typography>
                </Grid>
            </Grid>
        </Grid>
    }

    const formatEpochToDate = (epochMs) => {
        try {
            const date = new Date(epochMs);
            if (isNaN(date.getTime())) {
                return null;
            }
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const year = date.getFullYear();
            return `${month}/${day}/${year}`;
        } catch (error) {
            return null;
        }
    };


    return (
      <Formik
        initialValues={{
            time_units: timeUnitsConfigs['time_units'].map((t, index) => {
                  return {
                      ...t,
                      order: index,
                      start_time: t.start_time ? new Date(t.start_time).toISOString() : '',
                      end_time: t.end_time ? new Date(t.end_time).toISOString() : '',
                  }
              }
            ),
            time_unit_type:timeUnitsConfigs['time_unit_type']??'MANUAL',
            trend_diff_timeunit_lower: timeUnitsConfigs['trend_diff_timeunit_lower'],
            trend_diff_timeunit_upper: timeUnitsConfigs['trend_diff_timeunit_upper'],
            trend_diff_timeunit_start: timeUnitsConfigs['trend_diff_timeunit_start'],
            trend_diff_timeunit_end: timeUnitsConfigs['trend_diff_timeunit_end'],
            start_value: timeUnitsConfigs.start_value,
            end_value: timeUnitsConfigs.end_value,
            date_column: timeUnitsConfigs['date_column']
        }}
        validationSchema={validationSchema}
        context={{ timeUnitType: timeUnitType }}
        innerRef={formRef}
        enableReinitialize={true}
        onSubmit={handleSubmit}>
          {({
                handleChange,
                values,
                touched,
                errors,
                setFieldValue
            }) => (
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12} container spacing={2} justifyContent={"flex-end"}>
                        <Grid item>
                            <Button startIcon={<FileUploadIcon/>}
                                    size={"small"}
                                    onClick={handleUpload}
                                    variant={'outlined'}>
                                {t('Upload CSV')}
                            </Button>
                            <input ref={inputRef} accept=".csv" onChange={processFile} type={'file'} hidden/>
                        </Grid>
                        <Grid item>
                            <Button startIcon={<DownloadIcon/>}
                                    size={"small"}
                                    onClick={handleDownload}
                                    variant={'outlined'}>
                                {t('Download Time Units CSV')}
                            </Button>
                        </Grid>
                    </Grid>
                    {/* Time unit type selector and date range pickers */}
                    <Grid container spacing={1} item xs={12}>
                        <Grid item spacing={1} xs={12}>

                        </Grid>
                    </Grid>
                    <Grid container spacing={1} item xs={12}>
                        <CustomInputRow title={t("feature.dataset.configure.frequency_column")} size={4}>
                            <CustomAutocomplete
                              id="time_unit_type"
                              name="time_unit_type"
                              fullWidth
                              size="small"
                              options={[
                                  { id: 'MANUAL', label: 'Custom' },
                                  { id: 'INT', label: 'Int' },
                                  { id: 'STRING', label: 'String' },
                                  { id: 'DAILY', label: 'Day' },
                                  { id: 'MONTHLY', label: 'Month' },
                                  { id: 'QUARTERLY', label: 'Quarter' },
                                  { id: 'YEARLY', label: 'Year' }
                              ]}
                              value={values.time_unit_type}
                              onChange={(e, value) => {
                                  // Create a synthetic event object that matches the original Select's event structure
                                  const syntheticEvent = {
                                      target: {
                                          value: value ? value.id : ''
                                      }
                                  };
                                  handleTimeUnitTypeChange(syntheticEvent, setFieldValue, values);
                              }}
                              autoSelect
                              disableCloseOnSelect={false}
                              error={Boolean(touched.time_unit_type && errors.time_unit_type)}
                              helperText={touched.time_unit_type && errors.time_unit_type}
                              variant="outlined"
                              getOptionLabel={(option) => option.label || ''}
                              isOptionEqualToValue={(option, value) => option.id === value}
                            />
                        </CustomInputRow>
                    </Grid>
                    <Grid container spacing={1} item xs={12}>
                        <CustomInputRow title={t("feature.dataset.configure.date_column")} size={4}>
                            <CustomAutocomplete
                              id="date_column"
                              name="date_column"
                              fullWidth
                              options={
                                  timeUnitType === 'MANUAL' ?
                                    dataset.columns.filter(column => ['DATE', 'INT', 'STRING'].includes(column.d_type))
                                    : timeUnitType === 'INT' ? dataset.columns.filter(column => ['INT'].includes(column.d_type))
                                    : timeUnitType === 'STRING' ? dataset.columns.filter(column => ['STRING'].includes(column.d_type))
                                      : dataset.columns.filter(column => column.d_type === 'DATE')
                              }
                              value={values.date_column}
                              onChange={(e, value) => handleFeatureColumnChange(value, setFieldValue)}
                              autoSelect
                              disableCloseOnSelect={false}
                              error={Boolean(touched.date_column && errors.date_column)}
                              helperText={(() => {
                                  if (touched.date_column && errors.date_column) {
                                      return errors.date_column;
                                  }

                                  const selectedColumn = dataset.columns.find(col => col.id === values.date_column);
                                  if (selectedColumn?.description) {
                                      if (selectedColumn.data_type === 'DATE') {
                                          // For date columns, format the min/max as dates
                                          const minDate = formatEpochToDate(selectedColumn.description.min);
                                          const maxDate = formatEpochToDate(selectedColumn.description.max);

                                          if (minDate && maxDate) {
                                              return <span style={{fontSize: '1rem', color: 'gray'}}>(Range: min: {minDate} - max: {maxDate})</span>;
                                          }
                                      } else {
                                          // For numerical columns, show the raw values
                                          const min = selectedColumn.description.min;
                                          const max = selectedColumn.description.max;

                                          if (min !== undefined && max !== undefined) {
                                              return <span style={{fontSize: '1rem', color: 'gray'}}>(Range: min: {min} - max: {max})</span>;
                                          }
                                      }
                                  }
                                  return null;
                              })()}
                              select
                              variant="outlined"
                              size="small"
                            />
                        </CustomInputRow>
                        {(timeUnitType === 'INT' || timeUnitType === 'STRING') ? (
                          <>
                              <CustomInputRow title={t("feature.dataset.configure.start_value")} size={4}>
                                  <CustomAutocomplete
                                    id="start_value"
                                    name="start_value"
                                    fullWidth
                                    options={getDistinctValue(values).map(val => ({
                                        id: val,
                                        name: val
                                    }))}
                                    value={values.start_value}
                                    onChange={(e, value) => handleValueChange('start', value, setFieldValue, values)}
                                    disableCloseOnSelect={false}
                                    error={Boolean(touched.start_value && errors.start_value)}
                                    helperText={touched.start_value && errors.start_value}
                                    select
                                    variant="outlined"
                                    size="small"
                                    disabled={tooManyValues}
                                  />
                                  {tooManyValues && (
                                    <Typography color="error" variant="caption">
                                        More than 150 records for time units. Please select another feature.
                                    </Typography>
                                  )}
                              </CustomInputRow>
                              <CustomInputRow title={t("feature.dataset.configure.end_value")} size={4}>
                                  <CustomAutocomplete
                                    id="end_value"
                                    name="end_value"
                                    fullWidth
                                    options={getDistinctValue(values).map(val => ({
                                        id: val,
                                        name: val
                                    }))}
                                    value={values.end_value}
                                    onChange={(e, value) => handleValueChange('end', value, setFieldValue, values)}
                                    disableCloseOnSelect={false}
                                    error={Boolean(touched.end_value && errors.end_value)}
                                    helperText={touched.end_value && errors.end_value}
                                    select
                                    variant="outlined"
                                    size="small"
                                    disabled={tooManyValues}
                                  />
                              </CustomInputRow>
                          </>
                        ) : (timeUnitType !== 'MANUAL') && (
                          <>
                              <CustomInputRow title={t("feature.dataset.configure.trend_diff_timeunit_start")} size={4}>
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <DatePicker
                                        format="MM/dd/yyyy"
                                        value={values.trend_diff_timeunit_start ? new Date(values.trend_diff_timeunit_start) : null}
                                        onChange={(newDate) => {
                                            handleDateRangeChange('start', newDate, setFieldValue);
                                        }}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                fullWidth: true,
                                                error: Boolean(touched.trend_diff_timeunit_start && errors.trend_diff_timeunit_start),
                                                helperText: touched.trend_diff_timeunit_start && errors.trend_diff_timeunit_start,
                                                inputProps: {
                                                    placeholder: 'MM/DD/YYYY'
                                                }
                                            }
                                        }}
                                        maxDate={values.trend_diff_timeunit_end ? new Date(values.trend_diff_timeunit_end) : undefined}
                                      />
                                  </LocalizationProvider>
                              </CustomInputRow>
                              <CustomInputRow title={t("feature.dataset.configure.trend_diff_timeunit_end")} size={4}>
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <DatePicker
                                        format="MM/dd/yyyy"
                                        value={values.trend_diff_timeunit_end ? new Date(values.trend_diff_timeunit_end) : null}
                                        onChange={(newDate) => {
                                            handleDateRangeChange('end', newDate, setFieldValue);
                                        }}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                fullWidth: true,
                                                error: Boolean(touched.trend_diff_timeunit_end && errors.trend_diff_timeunit_end),
                                                helperText: touched.trend_diff_timeunit_end && errors.trend_diff_timeunit_end,
                                                inputProps: {
                                                    placeholder: 'MM/DD/YYYY'
                                                }
                                            }
                                        }}
                                        minDate={values.trend_diff_timeunit_start ? new Date(values.trend_diff_timeunit_start) : undefined}
                                      />
                                  </LocalizationProvider>
                              </CustomInputRow>
                          </>
                        )}
                        {timeUnitType === 'MANUAL' && (
                          <>
                              <CustomInputRow title={t("feature.dataset.configure.trend_diff_timeunit_lower")} size={4}>
                                  <CustomAutocomplete
                                    id="trend_diff_timeunit_lower"
                                    name="trend_diff_timeunit_lower"
                                    fullWidth
                                    options={values.time_units.filter(t => t && t.id !== '').map(t => ({
                                        id: t.id,
                                        name: t.id
                                    }))}
                                    value={values.trend_diff_timeunit_lower}
                                    onChange={(e, value) => setFieldValue('trend_diff_timeunit_lower', value ? value.id : '')}
                                    disableCloseOnSelect={false}
                                    error={Boolean(touched.trend_diff_timeunit_lower && errors.trend_diff_timeunit_lower)}
                                    helperText={touched.trend_diff_timeunit_lower && errors.trend_diff_timeunit_lower}
                                    select
                                    variant="outlined"
                                    size="small"
                                  />
                              </CustomInputRow>
                              <CustomInputRow title={t("feature.dataset.configure.trend_diff_timeunit_upper")} size={4}>
                                  <CustomAutocomplete
                                    id="trend_diff_timeunit_upper"
                                    name="trend_diff_timeunit_upper"
                                    fullWidth
                                    options={values.time_units.filter(t => t && t.id !== '').map(t => ({
                                        id: t.id,
                                        name: t.id
                                    }))}
                                    value={values.trend_diff_timeunit_upper}
                                    onChange={(e, value) => setFieldValue('trend_diff_timeunit_upper', value ? value.id : '')}
                                    disableCloseOnSelect={false}
                                    error={Boolean(touched.trend_diff_timeunit_upper && errors.trend_diff_timeunit_upper)}
                                    helperText={touched.trend_diff_timeunit_upper && errors.trend_diff_timeunit_upper}
                                    select
                                    variant="outlined"
                                    size="small"
                                  />
                              </CustomInputRow>
                          </>
                        )}
                        {generating ? (
                          <Grid item xs={12} container justifyContent="center">
                              <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
                          </Grid>
                        ) : (
                          <>
                              {showMaxUnitsAlert && (
                                <Grid item xs={12}>
                                    <AlertCard
                                      message="Maximum time units (150) reached. Please add additional units manually if needed."
                                      severity="warning"
                                      height={80}
                                    />
                                </Grid>
                              )}
                              {(timeUnitType === 'MANUAL') && (
                                <FieldArray name="time_units">
                                    {({insert, remove, move}) => (
                                      <Grid item xs={7.2} container spacing={2}>
                                          {timeUnitType === 'MANUAL' && (
                                            <Grid item xs={12} container justifyContent="flex-start" spacing={2}>
                                                <Grid item>
                                                    <Button
                                                      startIcon={<AddCircleOutlineIcon />}
                                                      onClick={() => {
                                                          insert(values.time_units.length, {
                                                              id: '',
                                                              start_time: '',
                                                              end_time: '',
                                                              order: values.time_units.length,
                                                          })
                                                      }}
                                                      size="small"
                                                      variant="contained">
                                                        {t('Add Time Unit')}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                          )}
                                          {values.time_units.length > 0 && <Grid item container spacing={2} xs={12}>
                                              <Grid item xs={0.5}>
                                              </Grid>
                                              <Grid item xs={4.5}>
                                                  <Typography sx={{
                                                      maxWidth: '200px',
                                                      overflow: 'hidden',
                                                      textOverflow: 'ellipsis'
                                                  }}>{t("Time Unit")}</Typography>
                                              </Grid>
                                              <Grid item xs={3}>
                                                  <Typography sx={{
                                                      maxWidth: '200px',
                                                      overflow: 'hidden',
                                                      textOverflow: 'ellipsis'
                                                  }}>{t("Start Date")}</Typography>
                                              </Grid>
                                              <Grid item xs={3}>
                                                  <Typography sx={{
                                                      maxWidth: '200px',
                                                      overflow: 'hidden',
                                                      textOverflow: 'ellipsis'
                                                  }}>{t("End Date")}</Typography>
                                              </Grid>
                                          </Grid>}
                                          <Grid item xs={12} container sx={{
                                              maxHeight: `${height - 360}px`,
                                              minHeight: `${height - 360}px`,
                                              overflowY: "auto",
                                              overflowX: "hidden"
                                          }}>
                                              <TimeDataList handleChange={setFieldValue}
                                                            data={values['time_units']}
                                                            touched={touched}
                                                            errors={errors}
                                                            remove={remove}
                                                            onSortEnd={({oldIndex, newIndex}) => {
                                                                move(oldIndex, newIndex)
                                                            }}
                                                            useDragHandle
                                                            lockAxis="y"
                                                            lockToContainerEdges
                                                            lockOffset={["0%", "100%"]}
                                              />
                                          </Grid>
                                      </Grid>
                                    )}
                                </FieldArray>
                                    )}
                          </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
          )}
      </Formik>
    );
};

export default TimeDataConfigurationStep;