import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import FeatureFilters from './feature-filters';
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import Typography from '@mui/material/Typography';
import AlertCard from 'components/elements/AlertCard';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import InfiniteLoaderList from 'components/elements/InfiniteLoadList';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useFeaturesConfiguration from 'hooks/datasets/use-dataset-configuration/feature-configs';
import useDownloadDatasetFeatures from 'hooks/datasets/use-dataset-download/features';
import * as Yup from 'yup';
import Papa from 'papaparse';
import { json2csv } from 'json-2-csv';
import { saveAs } from 'file-saver';
import FeatureCardNew from './feature-card-new';
import ErrorDialog from '../../../../components/elements/ErrorDialog';

// Dummy data (replace with your dynamic data source)


const FormWithInfiniteScroll = ({ formRef, onSubmit, dataset, height, configs, handleOriginalConfigs }) => {
  const { control, handleSubmit, getValues,reset, formState: { errors, touched }, setValue } = useForm();
  // const formRef = useRef(null);
  const { t } = useTranslation();
  const { datasetId } = useParams();
  const [featuresConfigs, setFeaturesConfigs] = useState([]);
  const virtuoso = useRef(null);
  const inputRef = useRef();
  const [featuresUploaded, setFeaturesUploaded] = useState(false);
  const [search, setSearch] = useState('');
  const [validationErrors, setErrors] = useState(null);

  const { data, status, isFetchingNextPage, hasNextPage, fetchNextPage, isError } = useFeaturesConfiguration({
    datasetId,
    enabled: !featuresUploaded,
    search,
  });

  const { mutateAsync } = useDownloadDatasetFeatures();

  const validationSchema = Yup.object().shape({
    features: Yup.array()
      .of(
        Yup.object().shape({
          feature_name: Yup.string().required(t('feature.dataset.configure.features.feature_name.required')),
        }),
      ),
  });

  useEffect(() => {
    if (data) {
      const newData = data.pages.reduce((accum, curr) => {
        return [...accum, ...curr.data];
      }, []);
      handleOriginalConfigs({
        features: newData,
      });
      if (!configs['features']) {
        if (formRef && formRef.current) {
          // console.log(getValues())
          let processed = [];
          if (getValues() && getValues().rows) {
            processed = getValues().rows;
          }
          const initialData = [];
          const ids = new Set(initialData.map(d => d.feature));
          const merged = [...initialData, ...newData.filter(d => !ids.has(d.feature))];
          setFeaturesConfigs(merged);
        }
      } else {
        const initialData = [...configs['features']];
        const ids = new Set(initialData.map(d => d.feature));
        const merged = [...initialData, ...newData.filter(d => !ids.has(d.feature))];
        setFeaturesConfigs(merged);
      }
    }
  }, [data, configs]);

  useEffect(() => {
  }, [search]);

  const handleDownload = () => {
    mutateAsync({ datasetId }).then((res) => {
      let current = [];
      if (getValues() && getValues().rows) {
        current = Object.values(getValues().rows);
      }
      let mergedArray = [];

      if (res && res.data && res.data.features) {
        mergedArray = res.data.features.map(item =>
          current.find(replacement => replacement.feature === item.feature) || item,
        );
      }
      const csv = json2csv(mergedArray.map(({ detected_data_type, ...rest }) => rest));
      saveAs(new Blob([csv], { type: 'text/csv;charset=utf-8;' }), `${dataset ? dataset.name : ''}_features_${getFormattedTimestamp()}`);
    });
  };

  const handleUpload = () => {
    inputRef.current.click();
  };

  const getFormattedTimestamp = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}${month}${day}T${hours}${minutes}${seconds}`;
  };

  const checkIfKeyExist = (objectName, keys) => {
    return Object.keys(objectName).every(key => keys.includes(key));
  };

  const resetFileInput = () => {
    inputRef.current.value = '';
  };

  const processFile = (event) => {
    const fileUploaded = event.target.files[0];
    Papa.parse(fileUploaded, {
      header: true,
      skipEmptyLines: true,
      complete: function(results) {
        setFeaturesUploaded(true);
        const featureIds = new Set(dataset['columns'].map(d => d.id));
        let features = [];
        let uploadErrors = [];
        for (const result of results.data) {
          const keyExist = checkIfKeyExist(result, ['feature', 'block_from_ppsm', 'feature_name', 'data_type', 'trend_operation']);
          if (!keyExist) {
            uploadErrors.push(`CSV must only contain column names feature, feature_name,data_type,block_from_ppsm and trend_operation.`);
            break;
          }

          if (!featureIds.has(result['feature'])) {
            uploadErrors.push(`${result['feature']} feature is not available in the uploaded dataset.`);
          }

          if (!['NUMERICAL', 'BOOLEAN', 'CATEGORICAL', 'DATE'].includes(result['data_type'])) {
            uploadErrors.push(`Data type ${result['data_type']} of feature ${result['feature']} is invalid and should be one of NUMERICAL, BOOLEAN, CATEGORICAL or DATE`);
          }

          if (!['NUMERICAL', 'BOOLEAN'].includes(result['data_type']) && ['mean', 'sum', 'median'].includes(result['trend_operation'])) {
            uploadErrors.push(`Trend operation ${result['trend_operation']} for ${result['feature']} is invalid and only available for NUMERICAL or BOOLEAN features`);
          }

          const dataType = dataset['columns'].find(ele=>ele['id']===result['feature'])?.['data_type']
          if(dataType!=='NUMERICAL'){
            if(dataType !== result['data_type']){
              uploadErrors.push(`The data type ${dataType} of feature ${result['feature']} cannot be changed to ${result['data_type']}`);
            }
          }else{
            if(!['NUMERICAL', 'CATEGORICAL'].includes( result['data_type'])){
              uploadErrors.push(`The data type ${dataType} of feature ${result['feature']} cannot be changed to ${result['data_type']}`);
            }
          }

          features.push({
            ...result,
            block_from_ppsm: result['block_from_ppsm'] === 'true',
            detected_data_type: dataType ??result['data_type'],
          });
        }
        if (uploadErrors.length === 0) {
          reset()
          setFeaturesConfigs(features);
        } else {
          setErrors(uploadErrors);
        }
        resetFileInput();
      },
    });
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleErrorClose = () => {
    setErrors(null);
  };

  const doSubmit = (data) => {
    onSubmit({
      features: Object.values(data.rows),
    });

  };

  useImperativeHandle(formRef, () => {
    return {
      submitForm: handleSubmit(doSubmit),
    };
  });


  return (
    <>
      <form ref={formRef}>
        {featuresConfigs &&
          <Grid item xs={12} pt={1} sx={{
            maxHeight: `${height}px`,
            minHeight: `${height}px`,
            overflowY: 'auto',
            overflowX: 'hidden',
          }}>
            <Grid container spacing={3}>
              <Grid item xs={12} container spacing={2}>
                <FeatureFilters handleSearch={handleSearch} />
                <Grid item container spacing={2} xs={8} justifyContent={'flex-end'}>
                  <Grid item>
                    <Button startIcon={<FileUploadIcon />}
                            onClick={handleUpload}
                            variant={'outlined'}>
                      {t('Upload Configuration')}
                    </Button>
                    <input ref={inputRef} accept='.csv' onChange={processFile} type={'file'}
                           hidden />
                  </Grid>
                  <Grid item>
                    <Button startIcon={<DownloadIcon />}
                            onClick={handleDownload}
                            variant={'outlined'}>
                      {t('Download Configuration')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container spacing={2} xs={12}>
                <Grid item xs={3}>
                  <Typography sx={{
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: 600,
                  }}>{t('Feature')}</Typography>
                </Grid>
                <Grid item xs={3.5}>
                  <Typography sx={{
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: 600,
                  }}>{t('Display Name')}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography sx={{
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: 600,
                  }}>{t('Data Type')}</Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <Typography sx={{
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: 600,
                  }}>{t('Block from PPSM')}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography sx={{
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: 600,
                  }}>{t('Trend Operation')}</Typography>
                </Grid>
              </Grid>

              {(status === 'error') &&
                <AlertCard severity={'error'} height={400} message={'Something went wrong !'} />}

              {status === 'loading' ? <Grid container justifyContent='center' alignItems='center'
                                            sx={{ width: '100%', minHeight: 400 }}>
                  <Grid item xs={12} container justifyContent='center' spacing={2}>
                    <Grid item xs={12} container justifyContent='center'>
                      <LoaderSpinner type='Bars' color='#175da8' secondaryColor={'#6abed5'}
                                     height={70} width={70} />
                    </Grid>
                    <Grid item>
                      <Typography>Loading features</Typography>
                    </Grid>
                  </Grid>
                </Grid> :

                <Grid item xs={12}>
                  <InfiniteLoaderList
                    height={height - 144}
                    data={featuresConfigs.filter(v => v['feature'].toLocaleLowerCase().includes(search.toLocaleLowerCase()))}
                    ref={virtuoso}
                    isFetchingNextPage={isFetchingNextPage || search !== ''}
                    hasNextPage={hasNextPage}
                    fetchNextPage={fetchNextPage}
                    isError={isError}
                    itemTemplate={({ data, index }) => <FeatureCardNew values={getValues().rows} control={control}
                                                                       row={data} index={index} errors={errors}
                                                                       touched={touched} />}
                  />
                </Grid>
              }

            </Grid>
          </Grid>
        }
      </form>
      <ErrorDialog open={validationErrors} title={'Failed to upload file.'} handleClose={handleErrorClose} />
    </>
  );
};

export default FormWithInfiniteScroll;
