import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import styled from '@mui/system/styled';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Divider from '@mui/material/Divider';

import TrendsChart from './trends-chart';
import TrendDrilldownChartContainer from './trend-drilldown-chart-container';
import TrendDrilldownChartInfiniteContainer from './trend-drilldown-chart-infinite-container';

import TopDriversView from './top-drivers-view';

const TrendTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 600,
}));

const TrendsDrilldown = ({}) => {
  const { t } = useTranslation();
  const featureId = useSelector((state) => state.app.trends.featureId);
  const color = useSelector((state) => state.app.trends.featureColor);
  const [trendDiffTimeUnitLower, setTrendDiffTimeUnitLower] = useState('');
  const [trendDiffTimeUnitUpper, setTrendDiffTimeUnitUpper] = useState('');
  const [selectedTrend, setSelectedTrend] = useState(null);
  const [overallTrend, setOverallTrend] = useState(null);
  const [positiveTrend, setPositiveTrend] = useState(null);
  const [negativeTrend, setNegativeTrend] = useState(null);
  const [viewAll, setViewAll] = useState(false);
  const datasetId = useSelector((state) => state.app.dataset);
  const [noChange, setNoChange] = useState(true);
  const [color2, setColor2] = useState('#00f1ff');

  const [totalPositive, setTotalPositive] = useState(0);
  const [totalNegative, setTotalNegative] = useState(0);
  const [totalZero, setTotalZero] = useState(0);

  useEffect(() => {
    setViewAll(false);
  }, [datasetId]);

  const handleOverallTrend = (trend) => {
    setOverallTrend(trend);
  };

  const handlePositiveTrend = (trend) => {
    setPositiveTrend(trend);
  };

  const handleNegativeTrend = (trend) => {
    setNegativeTrend(trend);
  };

  useEffect(() => {
    setSelectedTrend(null);
    setNoChange(true);
  }, [featureId]);

  const openDrilldown = (trend, color) => {
    setSelectedTrend(trend);
    setColor2(color);
  };

  const handleTrendDiffTimeUnit = (lower, upper) => {
    setTrendDiffTimeUnitLower(lower);
    setTrendDiffTimeUnitUpper(upper);
  };

  const handleViewAll = () => {
    setViewAll(true);
  };

  const handleCloseAll = () => {
    setViewAll(false);
  };

  const handleNoChange = (status) => {
    setNoChange(status);
  };

  const handleTotal = (total, direction) => {
    switch (direction) {
      case 'pos':
        setTotalPositive(total);
        return;
      case 'neg':
        setTotalNegative(total);
        return;
      case 'zero':
        setTotalZero(total);
        return;
      default:
        setTotalPositive(0);
        setTotalNegative(0);
        setTotalNegative(0);
        return;
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TrendTitle>
          {overallTrend ? t('feature.investigate.trends.drilldown.title', { feature: overallTrend.feature.name }) : ''}
        </TrendTitle>
        {overallTrend && overallTrend.feature.trend_operation && (
          <Typography sx={{ fontSize: '0.875rem', textTransform: 'capitalize' }}>
            {t('feature.investigate.trends.drilldown.trendOperation', { operation: overallTrend.feature.trend_operation })}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12} data-testid={'trends-chart'} minHeight={'200px'} container justifyContent={'center'}>
        {overallTrend &&
          <Grid item md={12} lg={8} xl={8}>
            <TrendsChart xAxis={overallTrend.trend ? overallTrend.trend.x : []}
                         yAxis={overallTrend.trend ? overallTrend.trend.y : []}
                         trendDiffTimeUnit={trendDiffTimeUnitLower}
                         trendDiffTimeUnitUpper={trendDiffTimeUnitUpper}  
                         xAxis2={selectedTrend ? selectedTrend.trend.x : []}
                         yAxis2={selectedTrend ? selectedTrend.trend.y : []}
                         changeFromLast2={selectedTrend ? selectedTrend.change_from_last : 0}
                         changeFromLast1={overallTrend.change_from_last}
                         legend1={t('feature.investigate.trends.drilldown.legend')}
                         tooltipLabel={overallTrend.feature.name}
                         legend2={selectedTrend ? selectedTrend.id : ''}
                         overallTrend={overallTrend}
                         selectedTrend={selectedTrend}
                         trendDiffTimeUnitLower={trendDiffTimeUnitLower}
                         color1={color}
                         color2={color2}
                         xAxis3={overallTrend ? overallTrend.prediction_trend.x : []}
                         yAxis3={overallTrend ? overallTrend.prediction_trend.y : []}
                         xAxis4={selectedTrend ? selectedTrend.prediction_trend.x : []}
                         yAxis4={selectedTrend ? selectedTrend.prediction_trend.y : []}
            />
          </Grid>
        }
        {overallTrend &&
          <Grid item md={12} lg={4} xl={4} container spacing={2} sx={{ height: 'fit-content' }}>
            <TopDriversView overallTopDrivers={overallTrend.top_drivers}
                            selectedTopDrivers={selectedTrend ? selectedTrend.top_drivers : []} />
          </Grid>
        }
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ borderColor: '#dcdcdc' }} />
      </Grid>

      <Grid item xs={12}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '1.25rem',
          }}>{t('feature.investigate.trends.drilldown.segment.title', { feature: overallTrend ? overallTrend.feature.name : '' })}</Typography>
      </Grid>

      <Grid item xs={12} container spacing={2}>
        {positiveTrend && <Grid item xs={3}>
          <Typography
            sx={{ fontWeight: 700, fontSize: '0.875rem' }}>{'Biggest Positive Change'}</Typography>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '2.25rem',
            }}>{(positiveTrend.change_from_last * 100).toFixed(3)}<span
            style={{ fontSize: '0.875rem' }}>%</span></Typography>
          <Typography
            sx={{ fontSize: '0.875rem' }}>{positiveTrend.label}</Typography>

        </Grid>
        }
        {negativeTrend && <Grid item xs={3}>
          <Typography
            sx={{ fontWeight: 700, fontSize: '0.875rem' }}>{'Biggest Negative Change'}</Typography>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '2rem',
            }}>{(negativeTrend.change_from_last * 100).toFixed(3)}<span
            style={{ fontSize: '0.875rem' }}>%</span></Typography>
          <Typography
            sx={{ fontSize: '0.875rem' }}>{negativeTrend.label}</Typography>
        </Grid>
        }
      </Grid>
      <Grid item container spacing={4}>

        <Grid item xs={noChange ? 4 : 6}>
          {featureId &&  <TrendDrilldownChartContainer
            onClick={openDrilldown}
            title={'Positive % change'}
            testId={'segment-bar-chart'}
            direction={'pos'}
            datasetId={datasetId}
            featureId={featureId}
            selectedTrend={selectedTrend}
            handleOverallTrend={handleOverallTrend}
            handlePositiveTrend={handlePositiveTrend}
            handleTrendDiffTimeUnit={handleTrendDiffTimeUnit}
            defaultColor={'#00f1ff'}
            handleTotal={handleTotal}
          />
          }
        </Grid>
        <Grid item xs={noChange ? 4 : 6}>
          {featureId && <TrendDrilldownChartContainer
            onClick={openDrilldown}
            title={'Negative % change'}
            testId={'segment-bar-chart'}
            direction={'neg'}
            datasetId={datasetId}
            featureId={featureId}
            selectedTrend={selectedTrend}
            handleNegativeTrend={handleNegativeTrend}
            defaultColor={'#ff7ce8'}
            handleTotal={handleTotal}
          />}
        </Grid>
        {noChange && <Grid item xs={4}>
          {featureId && <TrendDrilldownChartContainer
            onClick={openDrilldown}
            title={'No % change'}
            testId={'segment-bar-chart'}
            direction={'zero'}
            datasetId={datasetId}
            featureId={featureId}
            selectedTrend={selectedTrend}
            handleNegativeTrend={handleNegativeTrend}
            handleNoChange={handleNoChange}
            defaultColor={'#000000'}
            handleTotal={handleTotal}
          />}
        </Grid>}
      </Grid>

      {viewAll && <Grid item container spacing={2}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '1.25rem',
            }}>{'Other segments'}</Typography>
        </Grid>
        <Grid item xs={noChange ? 4 : 6}>
          <TrendDrilldownChartInfiniteContainer
            onClick={openDrilldown}
            title={'Positive % change'}
            testId={'segment-bar-chart'}
            direction={'pos'}
            datasetId={datasetId}
            featureId={featureId}
            selectedTrend={selectedTrend}
            defaultColor={'#00f1ff'}
          />
        </Grid>
        <Grid item xs={noChange ? 4 : 6}>
          <TrendDrilldownChartInfiniteContainer
            onClick={openDrilldown}
            title={'Negative % change'}
            testId={'segment-bar-chart'}
            direction={'neg'}
            datasetId={datasetId}
            featureId={featureId}
            selectedTrend={selectedTrend}
            defaultColor={'#ff7ce8'}
          />
        </Grid>
        {noChange && <Grid item xs={4}>
          <TrendDrilldownChartInfiniteContainer
            onClick={openDrilldown}
            title={'No % change'}
            testId={'segment-bar-chart'}
            direction={'zero'}
            datasetId={datasetId}
            featureId={featureId}
            selectedTrend={selectedTrend}
            defaultColor={'#000000'}
          />
        </Grid>}
      </Grid>}
      {(totalPositive > 5 || totalNegative > 5 || totalZero > 5) &&
        <Grid item xs={12} container justifyContent='center'>
          <Grid item>
            <Button
              sx={{
                '&.MuiButton-root': {
                  '&:hover': { fontWeight: '700' },
                  backgroundColor: 'transparent',
                  textTransform: 'none',
                  paddingRight: '0',
                  paddingLeft: '0',
                },
              }}
              endIcon={viewAll ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              disableRipple
              onClick={!viewAll ? handleViewAll : handleCloseAll}
            >
              {!viewAll ? 'View all other Segments' : 'Close all other Segments'}
            </Button>
          </Grid>
        </Grid>}
    </Grid>
  );
};
export default TrendsDrilldown;
