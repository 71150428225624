import React, {useEffect, useState} from 'react';
import styled from '@mui/system/styled';
import {
  Grid,
  FormHelperText,
  FormControl,
  Radio,
  Checkbox,
  Typography,
  ListItem,
  ListItemButton,
  ListItemText
} from '@mui/material';
import {Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

const SummaryTitle = styled(Typography)(({theme}) => ({
  fontSize: '0.95rem',
  fontWeight: '500',
}));

const DosageRunAnalysisForm = ({
                                 treatments = [],
                                 outcomes = [],
                                 formRef,
                                 onSubmit,
                                 request
                               }) => {
  const {t} = useTranslation();
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [selectedOutcomes, setSelectedOutcomes] = useState([]);

  const validationSchema = Yup.object().shape({
    treatment: Yup.string()
      .required(t('A treatment is required')),
    outcomes: Yup.array()
      .min(1, t('Select at least 1 outcome'))
      .required(t('At least 1 outcome is required')),
  });

  const handleTreatmentChange = (treatmentId, setFieldValue) => {
    setSelectedTreatment(treatmentId);
    setFieldValue('treatment', treatmentId);
  }

  const handleOutcomeChange = (outcomeId, setFieldValue) => {
    let selected = [...selectedOutcomes];
    if (selectedOutcomes.includes(outcomeId)) {
      selected = selected.filter(id => id !== outcomeId);
    } else {
      selected.push(outcomeId);
    }
    setSelectedOutcomes(selected);
    setFieldValue('outcomes', selected);
  }

  const handleSubmit = (values) => {
    if (values['outcomes'] && values['treatment']) {
      const treatment_dimension_matrix = [];
      const treatment = treatments.find(t => t.id === values['treatment']);
      if (treatment) {
        let row = {
          "TreatmentID": treatment.id,
          "Description": treatment.name,
        }

        for (const outcome of outcomes) {
          if (values['outcomes'].includes(outcome.id)) {
            row[outcome.id] = 1;
          } else {
            row[outcome.id] = 0;
          }
        }
        treatment_dimension_matrix.push(row);
      }

      onSubmit({
        treatment_dimension_matrix
      });
    }
  }

  useEffect(() => {
    if (request && request['treatment']) {
      setSelectedTreatment(request['treatment']);
    }

    if (request && request['outcomes']) {
      setSelectedOutcomes(request['outcomes']);
    }
  }, [request]);

  const checkedOutcomes = (outcome) => {
    return selectedOutcomes.includes(outcome);
  }

  return (
    <Grid container justify="center" spacing={2} pt={2}>
      <Formik
        initialValues={{
          treatment: request['treatment'] ?? '',
          outcomes: request['outcomes'] ?? []
        }}
        validationSchema={validationSchema}
        innerRef={formRef}
        onSubmit={handleSubmit}
      >
        {({
            handleChange,
            values,
            touched,
            errors,
            setFieldValue,
          }) => (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SummaryTitle>Select a Treatment</SummaryTitle>
              <FormHelperText error={Boolean(touched.treatment && errors.treatment)}>
                {touched.treatment && errors.treatment}
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{border: '0.1em solid #E9E9E9', width: '100%'}}>
                <Grid container spacing={1}>
                  {[...treatments]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((treatment) => (
                    <Grid item xs={4} key={`treatment-${treatment.id}`}>
                      <ListItem
                        disablePadding
                        dense
                        onClick={() => handleTreatmentChange(treatment.id, setFieldValue)}
                      >
                        <ListItemButton px={0}>
                          <Radio
                            edge="start"
                            value={treatment.id}
                            checked={selectedTreatment === treatment.id}
                            name="treatment-radio"
                            inputProps={{'aria-labelledby': treatment.id}}
                          />
                          <ListItemText id={treatment.id} primary={treatment.name}/>
                        </ListItemButton>
                      </ListItem>
                    </Grid>
                  ))}
                </Grid>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <SummaryTitle>Outcomes</SummaryTitle>
              <FormHelperText error={Boolean(touched.outcomes && errors.outcomes)}>
                {touched.outcomes && errors.outcomes}
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{border: '0.1em solid #E9E9E9', width: '100%'}}>
                <Grid container spacing={1}>
                  {[...outcomes]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((outcome) => (
                    <Grid item xs={4} key={`outcome-${outcome.id}`}>
                      <ListItem
                        disablePadding
                        dense
                        onClick={() => handleOutcomeChange(outcome.id, setFieldValue)}
                      >
                        <ListItemButton px={0}>
                          <Checkbox
                            edge="start"
                            value={outcome.id}
                            checked={checkedOutcomes(outcome.id)}
                            name={outcome.id}
                            inputProps={{'aria-labelledby': outcome.id}}
                          />
                          <ListItemText id={outcome.id} primary={outcome.name}/>
                        </ListItemButton>
                      </ListItem>
                    </Grid>
                  ))}
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </Formik>
    </Grid>
  );
};

export default DosageRunAnalysisForm;