import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useResizeDetector } from 'react-resize-detector';
import Box from '@mui/material/Box';
import HC_more from 'highcharts/modules/annotations';
import useDebounce from 'hooks/useDebounce';
import ReactDOMServer from 'react-dom/server';
import NorthIcon from '@mui/icons-material/North';

HC_more(Highcharts);

const TrendsChart = ({
                       legend1,
                       legend2,
                       tooltipLabel = '',
                       xAxis = [],
                       yAxis = [],
                       xAxis2 = [],
                       yAxis2 = [],
                       xAxis3 = [],
                       yAxis3 = [],
                       xAxis4 = [],
                       yAxis4 = [],
                       overallTrend = null,
                       selectedTrend = null,
                       trendDiffTimeUnitUpper = '',
                       trendDiffTimeUnitLower = '',
                       color1,
                       color2,
                     }) => {

  let html = ReactDOMServer.renderToString(<div>
    {overallTrend && <div style={{ paddingLeft: '1em', maxWidth: '400px', minWidth: '360px' }}>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div style={{
          display: 'flex',
          paddingRight: '0.5em',
          width: 'fit-content',
          alignItems: 'center',
          height: '1.25rem',
        }}><span style={{
          backgroundColor: color1,
          width: '0.875em',
          height: '0.875em',
          borderRadius: '100%',
          cursor: 'pointer',
        }}></span>
        </div>
        <span
          style={{
            fontWeight: 700,
            fontSize: '1.25rem',
          }}>{(overallTrend.change_from_last * 100).toFixed(3)}
          <span style={{ fontSize: '0.875rem' }}>%</span></span>
        <span style={{ paddingLeft: '0.5em' }}></span>
        <span style={{ paddingBottom: '0em' }}>
          {overallTrend.change_from_last > 0 &&
            <NorthIcon style={{
              transform: `rotate(0deg)`,
              fontSize: '0.875rem',
              width: '1.5em',
              height: '1.25em',
            }} />}
          {overallTrend.change_from_last < 0 && <NorthIcon
            style={{
              transform: `rotate(180deg)`,
              fontSize: '0.875rem',
              width: '1.5em',
              height: '1.25em',
            }} />}
        </span>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <span
        style={{ fontSize: '0.875rem', whiteSpace: 'normal' }}>
        {`${overallTrend.change_from_last === 0 ? 'No change' : `${Math.abs(overallTrend.change_from_last * 100).toFixed(3)}% ${overallTrend.is_projected?'projected':''} ${overallTrend.change_from_last > 0 ? 'increase' : 'decrease'}`} in ${overallTrend.feature.name} for Overall population in ${trendDiffTimeUnitUpper} compared to ${trendDiffTimeUnitLower}`}
      </span>
      </div>

    </div>}
    {selectedTrend && <div style={{ paddingLeft: '1em', paddingTop: '1em', maxWidth: '400px', minWidth: '360px' }}>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div style={{
          display: 'flex',
          paddingRight: '0.5em',
          width: 'fit-content',
          alignItems: 'center',
          height: '1.25rem',
        }}><span style={{
          backgroundColor: color2,
          width: '0.875em',
          height: '0.875em',
          borderRadius: '100%',
          cursor: 'pointer',
        }}></span>
        </div>
        <span
          style={{
            fontWeight: 700,
            fontSize: '1.25rem',
          }}>{(selectedTrend.change_from_last * 100).toFixed(3)}
          <span style={{ fontSize: '0.875rem' }}>%</span></span>
        <span style={{ paddingLeft: '0.5em' }}></span>
        <span style={{ paddingBottom: '0em' }}>
          {selectedTrend.change_from_last > 0 &&
            <NorthIcon style={{
              transform: `rotate(0deg)`,
              fontSize: '0.875rem',
              width: '1.5em',
              height: '1.25em',
            }} />}
          {selectedTrend.change_from_last < 0 && <NorthIcon
            style={{
              transform: `rotate(180deg)`,
              fontSize: '0.875rem',
              width: '1.5em',
              height: '1.25em',
            }} />}
        </span>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <span
        style={{ fontSize: '0.875rem', whiteSpace: 'normal' }}>
        {`${selectedTrend?.change_from_last === 0 ? 'No change' : `${Math.abs(selectedTrend.change_from_last * 100).toFixed(3)}% ${selectedTrend.is_projected?'projected':''} ${selectedTrend.change_from_last > 0 ? 'increase' : 'decrease'}`} in ${overallTrend?.feature.name} for ${selectedTrend?.label} in ${trendDiffTimeUnitUpper} compared to ${trendDiffTimeUnitLower}`}
      </span>
      </div>

    </div>}
  </div>);
  const charOptions = () => {
    return {
      chart: {
        height: '480px',
        animation: false,
        spacingBottom: 0,
        type: 'line',
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      xAxis: {
        enabled: true,
        labels: {
          style: {
            fontSize: '0.875rem',
          },
        },
        gridLineWidth: 0,
        width: '80%',
        right: '20%',
      },
      yAxis: {
        title: {
          text: null,
        },
        labels: {
          enabled: true,
          style: {
            fontSize: '0.875rem',
          },
        },
        gridLineWidth: 0,
        ...(selectedTrend ? {
          height: '60%',
          top: '40%',
        } : {
          height: '80%',
          top: '20%',
        }),
      },
      legend: {
        itemStyle: {
          fontSize: '0.875rem',
        },
        padding: 4,
      },
      tooltip: {
        backgroundColor: '#ffffff',
        borderColor: '#e5e5e5',
        outside: true,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: false,
          },
        },
      },
    };
  };

  const [options, setOptions] = useState(charOptions());
  const chartRef = useRef(null);
  const { width, ref } = useResizeDetector();
  const chartWidth = useDebounce(width, 200);

  useEffect(() => {
    let xAxisValues =[]

    xAxis.forEach(x=>{
      if(!xAxisValues.includes(x)){
        xAxisValues.push(x)
      }
    })

    xAxis3.forEach(x=>{
      if(!xAxisValues.includes(x)){
        xAxisValues.push(x)
      }
    })

    xAxis4.forEach(x=>{
      if(!xAxisValues.includes(x)){
        xAxisValues.push(x)
      }
    })

    let zoneIndex = xAxisValues.findIndex(x => x === trendDiffTimeUnitLower);
    let upperIndex = xAxisValues.findIndex(x => x === trendDiffTimeUnitUpper);

    setOptions({
      ...charOptions(),
      xAxis: {
        type: 'category',
        categories: xAxisValues,
        labels: {
          style: {
            fontSize: '0.875rem',
          },
        },
        gridLineWidth: 0,
        plotLines: [{
          color: '#6690a1',
          width: 1,
          value: zoneIndex,
          zIndex: 5,
          dashStyle: 'Dash',
        },
        {
          color: '#ff5733',
          width: 1,
          value: upperIndex,
          zIndex: 5,
          dashStyle: 'Dash',
        }],
      },
      annotations: [
        {
          labelOptions: {
            backgroundColor: 'rgba(255,255,255,0.5)',
            verticalAlign: 'top',
            align: 'left',
            useHTML: true,
            shape: 'rect',
            draggable: '',
            allowOverlap: true,
            formatter: function() {
              return html;
            },
          },
          labels: [{
            align: 'left',
            borderWidth: 0,
            point: {
              xAxis: 0,
              x: zoneIndex,
              y: -15,
            },
          },
          ],
        },
      ],
      series: [
        {
          name: `${legend1}`,
          lineWidth: 3,
          data: yAxis.map((y, index) => {
            return [xAxis[index], y];
          }),
          color: color1,
          showInLegend: legend1 !== '',
          marker: {
            symbol: 'circle',
          },
          tooltip: {
            headerFormat: '',
            useHTML: true,
            pointFormatter: function() {
              return `<p style='font-size: 0.875rem;'>${this.category}<br><b>${tooltipLabel}</b>: ${isNaN(this.y) ? this.y : (this.y).toFixed(3)}</P>`;
            },
          },
        },
        {
          name: `${legend2}`,
          data: yAxis2.map((y, index) => {
            return [xAxis2[index], y];
          }),
          color: color2,
          lineWidth: 3,
          showInLegend: legend2 !== '',
          marker: {
            symbol: 'circle',
          },
          tooltip: {
            headerFormat: '',
            useHTML: true,
            pointFormatter: function() {
              return `<span style='font-size: 0.875rem;' >${this.category}<br><b>${tooltipLabel}</b>: ${isNaN(this.y) ? this.y : (this.y).toFixed(3)}</span>`;
            },
          },
        },
        {
          name: `${legend1} prediction`,
          data: yAxis3.map((y, index) => {
            return [xAxis3[index], y];
          }),
          color: color1,
          lineWidth: 2,
          showInLegend: legend1 !== '' && yAxis3.length>0,
          marker: {
            symbol: 'circle',
          },
          dashStyle: 'Dash',
          tooltip: {
            headerFormat: '',
            useHTML: true,
            pointFormatter: function() {
              return `<span style='font-size: 0.875rem;' >${this.category}<br><b>${tooltipLabel}</b>: ${isNaN(this.y) ? this.y : (this.y).toFixed(3)}</span>`;
            },
          },
        },
        {
          name: `${legend2} prediction`,
          data: yAxis4.map((y, index) => {
            return [xAxis4[index], y];
          }),
          color: color2,
          lineWidth: 2,
          showInLegend: legend2 !== '' && yAxis4.length>0,
          marker: {
            symbol: 'circle',
          },
          dashStyle: 'Dash',
          tooltip: {
            headerFormat: '',
            useHTML: true,
            pointFormatter: function() {
              return `<span style='font-size: 0.875rem;' >${this.category}<br><b>${tooltipLabel}</b>: ${isNaN(this.y) ? this.y : (this.y).toFixed(3)}</span>`;
            },
          },
        },
      ],
    });
  }, [xAxis, xAxis2, yAxis, yAxis2, legend1, legend2, trendDiffTimeUnitLower, trendDiffTimeUnitUpper, color1, color2]);

  useEffect(() => {
    if (chartRef) {
      chartRef.current.chart.reflow();
    }
  }, [chartWidth]);

  return (<Box ref={ref}>
      <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />
    </Box>
  );
};

export default TrendsChart;
