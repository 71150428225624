import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Tab,
} from '@mui/material';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {useParams} from 'react-router-dom';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import {Formik} from 'formik';
import {saveAs} from 'file-saver';
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import * as YAML from 'yaml';
import Typography from '@mui/material/Typography';

import useAnalysisConfiguration from 'hooks/datasets/use-dataset-configuration/analysis-configs';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import AlertCard from 'components/elements/AlertCard';
import ErrorDialog from 'components/elements/ErrorDialog';
import AdvancedRunConfiguration from './advanced-run-configuration';
import GeneralRunConfiguration from './general-run-configuration';

const RunConfigurationStep = ({formRef, dataset, height, onSubmit, configs,handleOriginalConfigs}) => {
    const {t} = useTranslation();
    const {datasetId} = useParams();
    const inputRef = useRef();
    const [runConfigs, setRunConfigs] = useState(null);
    const [errors, setErrors] = useState(null);
    const [value, setValue] = React.useState('0');

    const {data, status} = useAnalysisConfiguration({
        datasetId
    });

    const validationSchema = Yup.object().shape({
        processing_parameters: Yup.object().shape({
            norm_option: Yup.string().required('Norm option is required'),

            mom_threshold: Yup.number()
              .min(0, 'MoM Threshold must be at least 0')
              .max(1.0, 'MoM Threshold cannot exceed 1.0')
              .required('MoM Threshold is required'),

            nan_threshold: Yup.number()
              .min(0, 'NaN Threshold must be at least 0')
              .max(1.0, 'NaN Threshold cannot exceed 1.0')
              .required('NaN Threshold is required'),

            train_test_split: Yup.number()
              .min(0, 'Train Test Split must be at least 0')
              .max(1.0, 'Train Test Split cannot exceed 1.0')
              .required('Train Test Split is required'),

            caliper_threshold: Yup.number()
              .min(0, 'Caliper Threshold must be at least 0')
              .max(1.0, 'Caliper Threshold cannot exceed 1.0')
              .required('Caliper Threshold is required'),

            correlation_threshold: Yup.number()
              .min(0, 'Correlation Threshold must be at least 0')
              .max(1.0, 'Correlation Threshold cannot exceed 1.0')
              .required('Correlation Threshold is required'),

            feature_importance_threshold: Yup.number()
              .min(0, 'Feature Importance Threshold must be at least 0')
              .max(1.0, 'Feature Importance Threshold cannot exceed 1.0')
              .required('Feature Importance Threshold is required'),

            categorical_max_unique_threshold: Yup.number()
              .min(0, 'Categorical Max Unique Threshold must be at least 0')
              .max(1.0, 'Categorical Max Unique Threshold cannot exceed 1.0')
              .required('Categorical Max Unique Threshold is required'),

            mom_thresh_for_non_stationary_test: Yup.number()
              .min(0, 'MoM Threshold for Non-Stationary Test must be at least 0')
              .max(1.0, 'MoM Threshold for Non-Stationary Test cannot exceed 1.0')
              .required('MoM Threshold for Non-Stationary Test is required'),

            effect_size_thresh_for_non_stationary_test: Yup.number()
              .min(0, 'Effect Size Threshold for Non-Stationary Test must be at least 0')
              .max(1.0, 'Effect Size Threshold for Non-Stationary Test cannot exceed 1.0')
              .required('Effect Size Threshold for Non-Stationary Test is required'),

            enable_linear_sum_assignment_in_matching: Yup.boolean(),

            match_with_replacement: Yup.boolean(),
        }),

        record_id: Yup.string().required(t("feature.dataset.configure.record_unique_id.required")).nullable(),
        outcomes: Yup.array(Yup.string().required(t("feature.dataset.configure.outcomes.required"))).min(1, t("feature.dataset.configure.outcomes.required")),
        // levers: Yup.array(Yup.string().required(t("feature.dataset.configure.levers.required"))).min(1, t("feature.dataset.configure.levers.required")),
        treatments: Yup.array(Yup.string().required(t("feature.dataset.configure.treatments.required"))).min(1, t("feature.dataset.configure.treatments.required")),

    });

    useEffect(() => {
        if (data && !configs['record_id']) {
            setRunConfigs(data.data);
            handleOriginalConfigs(data.data)
        }
    }, [data])

    useEffect(() => {
        if (configs && configs['outcomes']) {
            setRunConfigs({
                record_id: configs['record_id'],
                levers: configs['levers'],
                treatments: configs['treatments'],
                outcomes: configs['outcomes'],
                hard_matching_columns: configs['hard_matching_columns'],
                segment_features: configs['segment_features'],
                mean_feature_for_opportunity: configs['mean_feature_for_opportunity'],
                processing_parameters: {
                    ...configs['processing_parameters'],
                    enable_linear_sum_assignment_in_matching: getBoolean(configs['processing_parameters']['enable_linear_sum_assignment_in_matching']),
                    match_with_replacement: getBoolean(configs['processing_parameters']['match_with_replacement'])
                }
            })
        }
    }, [configs])

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    }

    const preprocessObject = (obj) => {
        return Object.fromEntries(
          Object.entries(obj).map(([key, value]) => [
              key,
              value === undefined ? "" : value,
          ])
        );
    };

    const handleDownload = () => {
        if(formRef.current){
            try {
                const yamlString = YAML.stringify(preprocessObject(formRef.current.values),{
                    defaultKeyType: "PLAIN",
                });
                const blob = new Blob([yamlString], { type: "text/yaml;charset=utf-8" });
                saveAs(blob, `organization-preferences${Date.now()}.yaml`);
            } catch (err) {
                console.error("Error converting JSON to YAML:", err);
            }
        }

    }

    const handleUpload = () => {
        inputRef.current.click();
    }

    const processFile = (event) => {
        const fileUploaded = event.target.files[0];
        const reader = new FileReader();
        reader.onload = function (event) {
            const y =YAML.parse(event.target.result)
            let uploadErrors = [];
            const keys = ['record_id','levers','treatments','outcomes','hard_matching_columns','segment_features','mean_feature_for_opportunity','processing_parameters'];
            for (const key of keys) {
                if (!(key in y)) {
                    uploadErrors.push(`${key} does not exist in the file.`);
                }
            }

            const rIds = new Set(dataset.columns.filter(column => {
                return column['data_type'] !== 'DATE' || column['data_type'] !== 'BOOLEAN';
            }).map(d => d.id))

            if(y[`record_id`]!=="" && !rIds.has(y[`record_id`])){
                uploadErrors.push(`record_id: The "${y[`record_id`]}" does not available or match any of the columns in the dataset.`);
            }

            const mfIds = new Set(dataset.columns.filter(column => {
                return column['data_type'] === 'NUMERICAL';
            }).map(d => d.id))
            if(y[`mean_feature_for_opportunity`]!=="" && !mfIds.has(y[`mean_feature_for_opportunity`])){
                uploadErrors.push(`mean_feature_for_opportunity: The "${y[`mean_feature_for_opportunity`]}" does not available or match any of the columns in the dataset.`);
            }

            const oIds = new Set(dataset.columns.filter(column => {
                return column['data_type'] === 'NUMERICAL' || column['data_type'] === 'BOOLEAN';
            }).map(d => d.id))
            if(Array.isArray(y[`outcomes`])){
                for (const value of y[`outcomes`]) {
                    if(!oIds.has(value)){
                        uploadErrors.push(`outcomes: The "${value}" does not available or match any of the columns in the dataset.`);
                    }
                }
            }else{
                uploadErrors.push(`outcomes: Should only include a list of features.`);
            }

            const lIds = new Set(dataset.columns.filter(column => {
                return column['data_type'] === 'NUMERICAL' || column['data_type'] === 'CATEGORICAL';
            }).map(d => d.id))
            if(Array.isArray(y[`levers`])){
                for (const value of y[`levers`]) {
                    if(!lIds.has(value)){
                        uploadErrors.push(`levers: The "${value}" does not available or match any of the columns in the dataset.`);
                    }
                }
            }else{
                uploadErrors.push(`levers: Should only include a list of features.`);
            }

            const tIds = new Set(dataset.columns.filter(column => {
                return column['data_type'] === 'BOOLEAN' || column['data_type'] === 'NUMERICAL';
            }).map(d => d.id))
            if(Array.isArray(y[`treatments`])){
                for (const value of y[`treatments`]) {
                    if(!tIds.has(value)){
                        uploadErrors.push(`treatments: The "${value}" does not available or match any of the columns in the dataset.`);
                    }
                }
            }else{
                uploadErrors.push(`treatments: Should only include a list of features.`);
            }

            const hmcIds = new Set(dataset['columns'].map(d => d.id))
            if(Array.isArray(y[`hard_matching_columns`])){
                for (const value of y[`hard_matching_columns`]) {
                    if(!hmcIds.has(value)){
                        uploadErrors.push(`hard_matching_columns: The "${value}" does not available or match any of the columns in the dataset.`);
                    }
                }
            }else{
                uploadErrors.push(`hard_matching_columns: Should only include a list of features.`);
            }

            const sfIds = new Set(dataset.columns.filter(column => {
                return column['data_type'] === 'NUMERICAL' || column['data_type'] === 'BOOLEAN' || column['data_type'] === 'CATEGORICAL';
            }).map(d => d.id))
            if(Array.isArray(y[`segment_features`])){
                for (const value of y[`segment_features`]) {
                    if(!sfIds.has(value)){
                        uploadErrors.push(`segment_features: The "${value}" does not available or match any of the columns in the dataset.`);
                    }
                }
            }else{
                uploadErrors.push(`segment_features: Should only include a list of features.`);
            }

            if(uploadErrors.length===0) {
                setRunConfigs({
                    record_id: y['record_id'],
                    levers: y['levers'],
                    treatments: y['treatments'],
                    outcomes: y['outcomes'],
                    hard_matching_columns: y['hard_matching_columns'],
                    segment_features: y['segment_features'],
                    mean_feature_for_opportunity: y['mean_feature_for_opportunity'],
                    processing_parameters: y['processing_parameters']
                })
                if (formRef.current) {
                    formRef.current.resetForm();
                }
            }else {
                setErrors(uploadErrors);
            }
        }
        reader.readAsText(fileUploaded);
        resetFileInput();
    }

    const resetFileInput = () => {
        inputRef.current.value = "";
    }

    const getBoolean = (value) => {
        if (typeof value === 'boolean') {
            return value
        } else {
            if (value.toLowerCase() === 'true') {
                return true
            } else if (value.toLowerCase() === 'false') {
                return false
            } else {
                return false
            }
        }
    }

    const handleErrorClose = () => {
        setErrors(null);
    };

    if (status === 'error') {
        return <AlertCard severity={'error'} height={400} message={'Something went wrong !'}/>;
    }

    if (status === 'loading' || !runConfigs) {
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', minHeight: 400}}>
            <Grid item xs={12} container justifyContent="center" spacing={2}>
                <Grid item xs={12} container justifyContent="center">
                    <LoaderSpinner type="Bars" color="#175da8" secondaryColor={"#6abed5"} height={70} width={70}/>
                </Grid>
                <Grid item>
                    <Typography>Loading run configurations</Typography>
                </Grid>
            </Grid>
        </Grid>
    }

    return (
       <> <Formik
            initialValues={{
                record_id: runConfigs['record_id'],
                levers: runConfigs['levers'],
                treatments: runConfigs['treatments'],
                outcomes: runConfigs['outcomes'],
                hard_matching_columns: runConfigs['hard_matching_columns'],
                segment_features: runConfigs['segment_features'],
                mean_feature_for_opportunity: runConfigs['mean_feature_for_opportunity'],
                processing_parameters: {
                    ...runConfigs['processing_parameters'],
                    enable_linear_sum_assignment_in_matching: getBoolean(runConfigs['processing_parameters']['enable_linear_sum_assignment_in_matching']),
                    match_with_replacement: getBoolean(runConfigs['processing_parameters']['match_with_replacement'])
                }
            }}
            validationSchema={validationSchema}
            innerRef={formRef}
            enableReinitialize={true}
            onSubmit={onSubmit}>
            {({
                  handleChange,
                  values,
                  touched,
                  errors,
                  setFieldValue
              }) => (
                <Grid container spacing={3}>
                    <Grid item xs={12} container spacing={2} justifyContent={"flex-end"}>
                        <Grid item>
                            <Button startIcon={<FileUploadIcon/>}
                                    size={"small"}
                                    onClick={handleUpload}
                                    variant={'outlined'}>
                                {t('Upload')}
                            </Button>
                            <input ref={inputRef} accept=".yaml,.yml" onChange={processFile} type={'file'} hidden/>
                        </Grid>
                        <Grid item>
                            <Button startIcon={<DownloadIcon/>}
                                    size={"small"}
                                    onClick={handleDownload}
                                    variant={'outlined'}>
                                {t('Download config yaml')}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} item xs={12}>
                        <TabContext value={value}>
                            <TabList onChange={handleTabChange} sx={{width: '100%'}} variant="fullWidth">
                                <Tab label={t("General")} value="0" sx={{textTransform: 'none'}}/>
                                <Tab label={t("Advanced")} value="1" sx={{textTransform: 'none'}}/>
                            </TabList>

                            <TabPanel value="0">
                                <Grid container sx={{
                                    maxHeight: `${height - 184}px`,
                                    minHeight: `${height - 184}px`,
                                    overflowY: "auto",
                                    overflowX: "hidden"
                                }}>
                                    <GeneralRunConfiguration field={values}
                                                              handleChange={setFieldValue}
                                                              touched={touched}
                                                              dataset={dataset}
                                                              errors={errors}/>
                                </Grid>
                            </TabPanel>
                            <TabPanel value="1">
                                <Grid container sx={{
                                    maxHeight: `${height - 184}px`,
                                    minHeight: `${height - 184}px`,
                                    overflowY: "auto",
                                    overflowX: "hidden"
                                }}>
                                    <AdvancedRunConfiguration field={values['processing_parameters']}
                                                              handleChange={setFieldValue}
                                                              touched={touched}
                                                              dataset={dataset}
                                                              errors={errors}/>
                                </Grid>
                            </TabPanel>
                        </TabContext>
                    </Grid>
                </Grid>
            )}
        </Formik>
    <ErrorDialog open={errors} title={'Failed to upload file.'} handleClose={handleErrorClose} />
    </>
    );
};

export default RunConfigurationStep;