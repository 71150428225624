import {useMutation} from '@tanstack/react-query';

import api from 'services/api';

const useDownloadDatasetFeatures = () => {
    const download = async (datasetId) => {
        try {
            const response = await api.get(`api/v1/datasets/${datasetId}/download/features`);

            const {
                data: {
                    payload,
                    meta,
                },
            } = await response.json();

            return {data: payload, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useMutation(({datasetId}) => download(datasetId));
};

export default useDownloadDatasetFeatures;