import {useInfiniteQuery} from '@tanstack/react-query';

import api from 'services/api';

const useOpportunitiesDrilldown = ({
                                       outcome,
                                       segment,
                                       segmentFeature,
                                       lever,
                                       meanFeature,
                                       impactLever,
                                       datasetId = '',
                                       enabled= true
                                   }) => {
    const fetchOpportunitiesDrilldown = async ({pageParam = 1}) => {
        try {
            const query = new URLSearchParams({
                page: pageParam,
            });

            if (outcome) {
                query.append('outcome', outcome);
            }

            if (meanFeature) {
                query.append('mean_feature', meanFeature);
            }

            if (datasetId) {
                query.append('dataset', datasetId)
            }

            const result = await api.get(`api/v1/opportunities/${segmentFeature}/${segment}/${lever}/drilldown?${query.toString()}`,{
              timeout: 20000,
          });
            const {
                data: {
                    payload,
                    meta,
                },
            } = await result.json();

            return {data: payload, meta};
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useInfiniteQuery(['opportunities-drilldown-v1', outcome, segment, segmentFeature, lever, impactLever, meanFeature, datasetId], fetchOpportunitiesDrilldown, {
        refetchOnWindowFocus: false,
        getNextPageParam: ({meta: {next_page}}, allPages) => {
            if (next_page === 0) return undefined;

            return next_page;
        },
        retry : false,
        enabled
    });
};

export default useOpportunitiesDrilldown;
