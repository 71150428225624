import React from 'react';
import {useTranslation} from 'react-i18next';
import {
    TextField,
} from '@mui/material';
import {getIn} from 'formik';
import Switch from '@mui/material/Switch';
import { MenuItem } from '@mui/material';
import { Tooltip, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


import CustomMultiSelect from 'components/elements/CustomMultiSelect';
import CustomInputRow from 'components/elements/CustomInputRow';


const AdvancedRunConfiguration = ({field, touched, errors, handleChange, dataset}) => {
  const {t} = useTranslation();

    return (
        <>
          <CustomInputRow title={
            <>
            {t("Norm Option")}
              <Tooltip title={t("Normalize the prediction scores")}
                       componentsProps={{
                         tooltip: {
                           sx: {
                             fontSize: '0.9rem',
                             maxWidth: 300,
                             p: 1.5,
                           },
                         },
                       }}
              >
                <IconButton size="small" sx={{ ml: 0.5 }}>
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>

            </>

          }
                          size={4}>
            <TextField
              id="norm_option"
              name="norm_option"
              select
              variant="outlined"
              fullWidth
              value={field.norm_option}
              onChange={(e) => handleChange(`processing_parameters.norm_option`, e.target.value)}
              error={Boolean(getIn(touched, `processing_parameters.norm_option`) && getIn(errors, `processing_parameters.norm_option`))}
              helperText={getIn(touched, `processing_parameters.norm_option`) && getIn(errors, `processing_parameters.norm_option`)}
              size="small"
            >
              <MenuItem value="z_score">{t("Z Score")}</MenuItem>
              <MenuItem value="minmax">{t("Min-Max")}</MenuItem>
              <MenuItem value="minmax_outlier">{t("Min-Max Outlier")}</MenuItem>
            </TextField>
          </CustomInputRow>

          <CustomInputRow title={
            <>
            {t("MoM Threshold")}
              <Tooltip title={t("Dropping columns based on this threshold. Columns with a score lower than this value will be removed.")}
                       componentsProps={{
                         tooltip: {
                           sx: {
                             fontSize: '0.9rem',
                             maxWidth: 300,
                             p: 1.5,
                           },
                         },
                       }}
              >
                <IconButton size="small" sx={{ ml: 0.5 }}>
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>

            </>

          } size={4}>
            <TextField
              id="mom_threshold"
              name="mom_threshold"
              variant="outlined"
              fullWidth
              value={field.mom_threshold}
              onChange={(e) => handleChange(`processing_parameters.mom_threshold`, Number(e.target.value))}
              error={Boolean(getIn(touched, `processing_parameters.mom_threshold`) && getIn(errors, `processing_parameters.mom_threshold`))}
              helperText={getIn(touched, `processing_parameters.mom_threshold`) && getIn(errors, `processing_parameters.mom_threshold`)}
              type="number"
              inputProps={{
                step: 0.1,
                min: 0,
                max: 1.0,
              }}
              size="small"
            />
          </CustomInputRow>

            <CustomInputRow title={
              <>
                {t("NaN Threshold")}
                <Tooltip
                  title={t("Calculating the ratio of NaN records in a column as NaN records/total records.")}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        fontSize: '0.9rem',
                        maxWidth: 300,
                        p: 1.5,
                      },
                    },
                  }}
                >
                  <IconButton size="large" sx={{ ml: 0.5 }}>
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </>
            }
                            size={4}>
                <TextField id={`nan_threshold`}
                           name={`nan_threshold`}
                           variant="outlined"
                           fullWidth
                           value={field.nan_threshold}
                           onChange={(e) => handleChange(`processing_parameters.nan_threshold`, Number(e.target.value))}
                           error={Boolean(getIn(touched, `processing_parameters.nan_threshold`) && getIn(errors, `processing_parameters.nan_threshold`))}
                           helperText={getIn(touched, `processing_parameters.nan_threshold`) && getIn(errors, `processing_parameters.nan_threshold`)}
                           type={"number"}
                           inputProps={{
                             step: 0.1,
                             min: 0,
                             max: 1.0,
                           }}
                           size="small"/>
            </CustomInputRow>

            <CustomInputRow title={
              <>
              {t("Train Test Split")}
                <Tooltip
                  title={t("The train_test_split threshold defines the fraction of the total data used for training the model.")}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        fontSize: '0.9rem',
                        maxWidth: 300,
                        p: 1.5,
                      },
                    },
                  }}
                >
                  <IconButton size="large" sx={{ ml: 0.5 }}>
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </>
            }

                            size={4}>
                <TextField id={`train_test_split`}
                           name={`train_test_split`}
                           variant="outlined"
                           fullWidth
                           value={field.train_test_split}
                           onChange={(e) => handleChange(`processing_parameters.train_test_split`, Number(e.target.value))}
                           error={Boolean(getIn(touched, `processing_parameters.train_test_split`) && getIn(errors, `processing_parameters.train_test_split`))}
                           helperText={getIn(touched, `processing_parameters.train_test_split`) && getIn(errors, `processing_parameters.train_test_split`)}
                           type={"number"}
                           inputProps={{
                             step: 0.1,
                             min: 0,
                             max: 1.0,
                           }}
                           size="small"/>
            </CustomInputRow>

              <CustomInputRow title={
                <>
                  {t("Caliper Threshold")}
                  <Tooltip
                    title={t("Caliper values are calculated during the matching process. Valid matches are determined if the matched record's caliper value is less than or equal to the caliper threshold. If the value is higher, those matched records will be eliminated.")}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: '0.9rem',
                          maxWidth: 300,
                          p: 1.5,
                        },
                      },
                    }}
                  >
                    <IconButton size="large" sx={{ ml: 0.5 }}>
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              }

                              size={4}>
                <TextField id={`caliper_threshold`}
                           name={`caliper_threshold`}
                           variant="outlined"
                           fullWidth
                           value={field.caliper_threshold}
                           onChange={(e) => handleChange(`processing_parameters.caliper_threshold`, Number(e.target.value))}
                           error={Boolean(getIn(touched, `processing_parameters.caliper_threshold`) && getIn(errors, `processing_parameters.caliper_threshold`))}
                           helperText={getIn(touched, `processing_parameters.caliper_threshold`) && getIn(errors, `processing_parameters.caliper_threshold`)}
                           type={"number"}
                           inputProps={{
                             step: 0.1,
                             min: 0,
                             max: 1.0,
                           }}
                           size="small"/>
            </CustomInputRow>

          <CustomInputRow title={
            <>
              {t("Correlation Threshold")}
              <Tooltip
                title={t("Drop features with high correlation based on the threshold")}
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontSize: '0.9rem',
                      maxWidth: 300,
                      p: 1.5,
                    },
                  },
                }}
              >
                <IconButton size="large" sx={{ ml: 0.5 }}>
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
          }

                          size={4}>
                <TextField id={`correlation_threshold`}
                           name={`correlation_threshold`}
                           variant="outlined"
                           fullWidth
                           value={field.correlation_threshold}
                           onChange={(e) => handleChange(`processing_parameters.correlation_threshold`, Number(e.target.value))}
                           error={Boolean(getIn(touched, `processing_parameters.correlation_threshold`) && getIn(errors, `processing_parameters.correlation_threshold`))}
                           helperText={getIn(touched, `processing_parameters.correlation_threshold`) && getIn(errors, `processing_parameters.correlation_threshold`)}
                           type={"number"}
                           inputProps={{
                             step: 0.1,
                             min: 0,
                             max: 1.0,
                           }}
                           size="small"/>
            </CustomInputRow>
              <CustomInputRow title={
                <>
                  {t("Feature Importance Threshold")}
                  <Tooltip
                    title={t("Eliminate columns that have a feature importance score lower than the threshold, based on the model's feature importance scores.")}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: '0.9rem',
                          maxWidth: 300,
                          p: 1.5,
                        },
                      },
                    }}
                  >
                    <IconButton size="large" sx={{ ml: 0.5 }}>
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              }

                              size={4}>
                <TextField id={`feature_importance_threshold`}
                           name={`feature_importance_threshold`}
                           variant="outlined"
                           fullWidth
                           value={field.feature_importance_threshold}
                           onChange={(e) => handleChange(`processing_parameters.feature_importance_threshold`, Number(e.target.value))}
                           error={Boolean(getIn(touched, `processing_parameters.feature_importance_threshold`) && getIn(errors, `processing_parameters.feature_importance_threshold`))}
                           helperText={getIn(touched, `processing_parameters.feature_importance_threshold`) && getIn(errors, `processing_parameters.feature_importance_threshold`)}
                           type={"number"}
                           inputProps={{
                             step: 0.001,
                             min: 0,
                             max: 1.0,
                           }}
                           size="small"/>
            </CustomInputRow>

              <CustomInputRow title={
                <>
                  {t("Categorical Max Unique Threshold")}
                  <Tooltip
                    title={t("Column has more than percent unique values and is not in the whitelisted columns, it will be dropped.")}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: '0.9rem',
                          maxWidth: 300,
                          p: 1.5,
                        },
                      },
                    }}
                  >
                    <IconButton size="large" sx={{ ml: 0.5 }}>
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              }

                              size={4}>
                <TextField id={`categorical_max_unique_threshold`}
                           name={`categorical_max_unique_threshold`}
                           variant="outlined"
                           fullWidth
                           value={field.categorical_max_unique_threshold}
                           onChange={(e) => handleChange(`processing_parameters.categorical_max_unique_threshold`, Number(e.target.value))}
                           error={Boolean(getIn(touched, `processing_parameters.categorical_max_unique_threshold`) && getIn(errors, `processing_parameters.categorical_max_unique_threshold`))}
                           helperText={getIn(touched, `processing_parameters.categorical_max_unique_threshold`) && getIn(errors, `processing_parameters.categorical_max_unique_threshold`)}
                           type={"number"}
                           inputProps={{
                             step: 0.1,
                             min: 0,
                             max: 1.0,
                           }}
                           size="small"/>
            </CustomInputRow>
              <CustomInputRow title={
                <>
                  {t("Minimum Pilot Records Count")}
                  <Tooltip
                    title={t("The specific target and treatment combination run will proceed only if the pilot count meets or exceeds the selected value for min-pilot-records-count.")}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: '0.9rem',
                          maxWidth: 300,
                          p: 1.5,
                        },
                      },
                    }}
                  >
                    <IconButton size="large" sx={{ ml: 0.5 }}>
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              }

                              size={4}>
              <TextField id={`min_pilot_records`}
                         name={`min_pilot_records`}
                         variant="outlined"
                         fullWidth
                         value={field.min_pilot_records??30}
                         onChange={(e) => handleChange(`processing_parameters.min_pilot_records`, Number(e.target.value))}
                         error={Boolean(getIn(touched, `processing_parameters.min_pilot_records`) && getIn(errors, `processing_parameters.min_pilot_records`))}
                         helperText={getIn(touched, `processing_parameters.min_pilot_records`) && getIn(errors, `processing_parameters.min_pilot_records`)}
                         type={"number"}
                         size="small"/>
            </CustomInputRow>

            <CustomInputRow title={
                <>
                  {t("Categorical Max Unique Limit")}
                  <Tooltip
                    title={t("Categorical Max unique Limit")}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: '0.9rem',
                          maxWidth: 300,
                          p: 1.5,
                        },
                      },
                    }}
                  >
                    <IconButton size="large" sx={{ ml: 0.5 }}>
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              }

                              size={4}>
                <TextField id={`categorical_max_unique_limit`}
                           name={`categorical_max_unique_limit`}
                           variant="outlined"
                           fullWidth
                           value={field.categorical_max_unique_limit}
                           onChange={(e) => handleChange(`processing_parameters.categorical_max_unique_limit`, Number(e.target.value))}
                           error={Boolean(getIn(touched, `processing_parameters.categorical_max_unique_limit`) && getIn(errors, `processing_parameters.categorical_max_unique_limit`))}
                           helperText={getIn(touched, `processing_parameters.categorical_max_unique_limit`) && getIn(errors, `processing_parameters.categorical_max_unique_limit`)}
                           type={"number"}
                           size="small"/>
            </CustomInputRow>
              <CustomInputRow title={
                <>
                  {t("Mom Threshold For Non Stationary Test")}
                  <Tooltip
                    title={t("Mom Threshold For Non Stationary Test")}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: '0.9rem',
                          maxWidth: 300,
                          p: 1.5,
                        },
                      },
                    }}
                  >
                    <IconButton size="large" sx={{ ml: 0.5 }}>
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              }

                              size={4}>
                <TextField id={`mom_thresh_for_non_stationary_test`}
                           name={`mom_thresh_for_non_stationary_test`}
                           variant="outlined"
                           fullWidth
                           value={field.mom_thresh_for_non_stationary_test}
                           onChange={(e) => handleChange(`processing_parameters.mom_thresh_for_non_stationary_test`, Number(e.target.value))}
                           error={Boolean(getIn(touched, `processing_parameters.mom_thresh_for_non_stationary_test`) && getIn(errors, `processing_parameters.mom_thresh_for_non_stationary_test`))}
                           helperText={getIn(touched, `processing_parameters.mom_thresh_for_non_stationary_test`) && getIn(errors, `processing_parameters.mom_thresh_for_non_stationary_test`)}
                           type={"number"}
                           inputProps={{
                             step: 0.1,
                             min: 0,
                             max: 1.0,
                           }}
                           size="small"/>
            </CustomInputRow>

              <CustomInputRow title={
                <>
                  {t("Effect Size Threshold For Non Stationary Test")}
                  <Tooltip
                    title={t("Effect Size Threshold For Non Stationary Test")}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: '0.9rem',
                          maxWidth: 300,
                          p: 1.5,
                        },
                      },
                    }}
                  >
                    <IconButton size="large" sx={{ ml: 0.5 }}>
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              }

                              size={4}>
                <TextField id={`effect_size_thresh_for_non_stationary_test`}
                           name={`effect_size_thresh_for_non_stationary_test`}
                           variant="outlined"
                           fullWidth
                           value={field.effect_size_thresh_for_non_stationary_test}
                           onChange={(e) => handleChange(`processing_parameters.effect_size_thresh_for_non_stationary_test`, Number(e.target.value))}
                           error={Boolean(getIn(touched, `processing_parameters.effect_size_thresh_for_non_stationary_test`) && getIn(errors, `processing_parameters.effect_size_thresh_for_non_stationary_test`))}
                           helperText={getIn(touched, `processing_parameters.effect_size_thresh_for_non_stationary_test`) && getIn(errors, `processing_parameters.effect_size_thresh_for_non_stationary_test`)}
                           type={"number"}
                           inputProps={{
                             step: 0.1,
                             min: 0,
                             max: 1.0,
                           }}
                           size="small"/>
            </CustomInputRow>

            <CustomInputRow title={t("Enable Linear Sum Assignment In Matching")} size={4}>
                <Switch
                    onChange={(e) => handleChange(`processing_parameters.enable_linear_sum_assignment_in_matching`, e.target.checked)}
                    checked={field.enable_linear_sum_assignment_in_matching}
                    name={'processing_parameters.enable_linear_sum_assignment_in_matching'}
                />
            </CustomInputRow>

            <CustomInputRow title={t("Match With Replacement")} size={4}>
                <Switch
                    onChange={(e) => {
                        handleChange(`processing_parameters.match_with_replacement`, e.target.checked)

                    }}
                    checked={field.match_with_replacement}
                    name={'processing_parameters.match_with_replacement'}
                />
            </CustomInputRow>

            <CustomInputRow title={t("Blacklisted Columns")} alignContent={'flex-start'}>
                <CustomMultiSelect
                    value={field.blacklisted_cols}
                    name="processing_parameters.blacklisted_cols"
                    inputProps={{
                        id: 'blacklisted_cols',
                    }}
                    searchable
                    height={200}
                    onChange={handleChange}
                    error={Boolean(getIn(touched, `processing_parameters.blacklisted_cols`) && getIn(errors, `processing_parameters.blacklisted_cols`))}
                    helperText={getIn(touched, `processing_parameters.blacklisted_cols`) && getIn(errors, `processing_parameters.blacklisted_cols`)}
                    options={dataset.columns}
                />
            </CustomInputRow>

            <CustomInputRow title={t("Whitelisted Columns From Dropping")} alignContent={'flex-start'}>
                <CustomMultiSelect
                    value={field.whitelisted_cols_from_dropping}
                    name="processing_parameters.whitelisted_cols_from_dropping"
                    inputProps={{
                        id: 'whitelisted_cols_from_dropping',
                    }}
                    searchable
                    height={200}
                    onChange={handleChange}
                    error={Boolean(getIn(touched, `processing_parameters.effect_size_thresh_for_non_stationary_test`) && getIn(errors, `processing_parameters.effect_size_thresh_for_non_stationary_test`))}
                    helperText={getIn(touched, `processing_parameters.effect_size_thresh_for_non_stationary_test`) && getIn(errors, `processing_parameters.effect_size_thresh_for_non_stationary_test`)}
                    options={dataset.columns}
                />
            </CustomInputRow>
        </>
    );
};

export default AdvancedRunConfiguration;