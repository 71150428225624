import React from 'react';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {TextField} from '@mui/material';
import {format, parseISO} from 'date-fns';
import {getIn} from 'formik';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {SortableElement, SortableHandle} from 'react-sortable-hoc';

const DragHandle = SortableHandle(() => <Grid item sx={{cursor: 'row-resize'}}>
    <DragIndicatorIcon/>
</Grid>);

const TimeDataCard = ({
                          timeUnit,
                          touched, errors, handleChange, remove, itemIndex, key
                      }) => {
    const {t} = useTranslation();
    const position = itemIndex;

    return (
        <Grid item xs={12} container p={0.5} spacing={2} key={key}>
            <Grid container alignContent={"center"} item xs={0.5}>
                <Grid item sx={{cursor: 'pan'}}>
                    <DragHandle/>
                </Grid>
            </Grid>
            <Grid item xs={4.5}>
                <TextField id={`time_units.${position}.id`}
                           name={`time_units.${position}.id`}
                           placeholder={t('Time Unit')}
                           variant="outlined"
                           fullWidth
                           required
                           value={timeUnit.id}
                           onChange={(e) => handleChange(`time_units[${position}].id`, e.target.value)}
                           error={Boolean(getIn(touched, `time_units[${position}].id`) && getIn(errors, `time_units[${position}].id`))}
                           helperText={getIn(touched, `time_units[${position}].id`) && getIn(errors, `time_units[${position}].id`)}
                           size="small"/>
            </Grid>
            <Grid item xs={3}>
                <TextField id={`time_units.${position}.start_time`}
                           name={`time_units.${position}.start_time`}
                           variant="outlined"
                           placeholder={t('Start Time')}
                           fullWidth
                           required
                           value={timeUnit.start_time ? format(parseISO(timeUnit.start_time), "yyyy-MM-dd") : ''}
                           onChange={(e) => handleChange(`time_units[${position}].start_time`, new Date(e.target.value).toISOString())}
                           error={Boolean(getIn(touched, `time_units[${position}].start_time`) && getIn(errors, `time_units[${position}].start_time`))}
                           helperText={getIn(touched, `time_units[${position}].start_time`) && getIn(errors, `time_units[${position}].start_time`)}
                           type={"date"}
                           size="small"/>
            </Grid>
            <Grid item xs={3}>
                <TextField id={`time_units.${position}.end_time`}
                           name={`time_units.${position}.end_time`}
                           variant="outlined"
                           placeholder={t('End Time')}
                           fullWidth
                           required
                           value={timeUnit.end_time ? format(parseISO(timeUnit.end_time), "yyyy-MM-dd") : ''}
                           onChange={(e) => handleChange(`time_units[${position}].end_time`, new Date(e.target.value).toISOString())}
                           error={Boolean(getIn(touched, `time_units[${position}].end_time`) && getIn(errors, `time_units[${position}].end_time`))}
                           helperText={getIn(touched, `time_units[${position}].end_time`) && getIn(errors, `time_units[${position}].end_time`)}
                           type={"date"}
                           size="small"/>
            </Grid>

            <Grid container alignContent={"center"} item xs={1}>
                <Grid item>
                    <IconButton onClick={() => remove(position)}><CloseIcon/></IconButton>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default SortableElement(TimeDataCard);